const buttons = () =>{
  return ({ addComponents, config}) => {
    addComponents(
      {
        '.btn': {
          border: `solid 1px transparent`,
          display: 'inline-block',
          cursor: 'pointer',
          fontSize: '12px',
          lineHeight: '1',
          outline: 'none',
          padding: '18px 35px',
          textAlign: 'center',
          textTransform: 'uppercase',
          transition: 'all .1s ease-out'
        },
        '.btn-sm': {
          fontSize: '15px',
          minHeight: '42px',
          //lineHeight: '42px',
          padding: '11px 20px 10px'
        },
        '.btn-md': {
          fontSize: '18px',
          minHeight: '48px',
          //lineHeight: '48px',
          padding: '13px 20px 12px'
        },
        '.btn-lg': {
          fontSize: '18px',
          minHeight: '56px',
          //lineHeight: '56px',
          padding: '16px 20px 15px'
        },
        '.btn:focus': {
          outline: 'none'
        },
        '.btn-primary': {
          backgroundColor: config().theme.colors.secondary,
          border: `solid 1px ${config().theme.colors.secondary}`,
          color: config().theme.colors.white,
          '&:hover': {
            backgroundColor: config().theme.colors.primary,
            border: `solid 1px ${config().theme.colors.primary}`
          }
        },
        '.btn-primary-outline': {
          backgroundColor: 'transparent',
          border: `solid 1px ${config().theme.colors.secondary}`,
          color: config().theme.colors.secondary,
          '&:hover': {
            border: `solid 1px ${config().theme.colors.primary}`,
            color: `${config().theme.colors.primary}`
          }
        },
        '.btn-atc-primary': {
          backgroundColor: config().theme.colors.atcPrimary,
          border: `solid 1px ${config().theme.colors.atcPrimary}`,
          color: config().theme.colors.white,
          '&:hover': {
            backgroundColor: config().theme.colors.atcPrimaryHover,
            border: `solid 1px ${config().theme.colors.atcPrimaryHover}`
          }
        },
        '.btn-outline': {
          backgroundColor: 'transparent',
          border: `solid 1px ${config().theme.colors.gray['200']}`,
          color: config().theme.colors.black
        },
        '.btn-secondary': {
          backgroundColor: config().theme.colors.primary,
          border: `solid 1px ${config().theme.colors.primary}`,
          color: config().theme.colors.white,
          '&:hover': {
            backgroundColor: config().theme.colors.secondary,
            border: `solid 1px ${config().theme.colors.secondary}`
          }
        },
        '.btn-signup': {
          backgroundColor: config().theme.colors.gwBlack,
          border: `solid 1px ${config().theme.colors.gwBlack}`,
          color: config().theme.colors.white
        }
      }
    , {
      variants: ['responsive', 'hover', 'focus', 'group-hover']
    })
  }
}

module.exports = buttons;