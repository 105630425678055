import { getRegion } from '@/lib/region-helper'
const region = getRegion(process.env.NEXT_PUBLIC_SITE_REGION)

export default {
  "title": "GOREWEAR",
  "titleTemplate": `%s | GOREWEAR ${region.countrySeoName}`,
  "description": "Shop the entire range of GOREWEAR performance running and cycling clothing for men and women. Free Shipping and express delivery available.",
  "openGraph": {
    "title": "GOREWEAR",
    "description": "Shop the entire range of GOREWEAR performance running and cycling clothing for men and women. Free Shipping and express delivery available.",
    "type": "website",
    "url": process.env.NEXT_PUBLIC_BASE_URL,
    "site_name": "GOREWEAR"
  }
}