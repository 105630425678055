import '../styles/index.css'
import 'flag-icon-css/css/flag-icon.min.css'
import 'notyf/notyf.min.css'
import { useEffect, useState } from 'react'
import Head from 'next/head'
import getConfig from 'next/config'
import { DefaultSeo } from 'next-seo'
import { useRouter } from 'next/router'
import { Notyf } from 'notyf'

// Redux
import { Provider } from 'react-redux'
import { store, persistor } from '@/redux/store/configureStore'
import { PersistGate } from 'redux-persist/integration/react'
import { onLocalLogin, getLocalstorageItem } from '@/redux/actions/userAction'

// Helpers
import seoConfig from '@/config/seo'
import regions from '@/config/regions'
import { removeTrailingSlash } from '@/lib/helpers'
import { updateSiteLocale, sendPixelData, getRegion } from '@/lib/region-helper'

// GTM
import { setAccountGTM, productPageGTM } from '@/lib/gtm'

// 3rd-party
import { ToastProvider } from 'react-toast-notifications'
import Cookies from 'js-cookie'

// Providers
import ProductProvider from '@/providers/product-provider'
import Script from 'next/script'

const Noop = ({ children }) => children

const devMode = process.env.NODE_ENV !== 'production'
// const isClient = typeof window !== 'undefined'
const isStaging = process.env.NEXT_PUBLIC_BASE_URL == 'https://www.origin.gorewear.com'
const isProd = process.env.NEXT_PUBLIC_BASE_URL === 'https://www.gorewear.com' ? true : false
const debugEnabled = process.env.NEXT_PUBLIC_DEBUG_ENABLED || 'false'
const { publicRuntimeConfig } = getConfig()
const region = getRegion(process.env.NEXT_PUBLIC_SITE_REGION)

const AppProvider = ({ Component, pageProps, setCustomer }) => {
  const Layout = Component.Layout || Noop
  const version = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA?.slice(-7)

  if (pageProps && pageProps?.page?.slug == 'homepage') {
    seoConfig.titleTemplate = '%s'
  } else {
    seoConfig.titleTemplate = `%s | GOREWEAR ${region.countrySeoName}`
  }

  return (
    <>
      <DefaultSeo {...seoConfig} />
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {isStaging && <meta name="AdsBot-Google" content="noindex,nofollow" />}
        <meta
          name="google-site-verification"
          content="x1HHAw8_lp30gcRM6JHTq8g7VMQ-5mkzvDnAugiFJPo"
        />
        <link rel="shortcut icon" href={`${publicRuntimeConfig.basePath}/favicon/favicon.ico`} />
      </Head>
      <ToastProvider autoDismiss={true} autoDismissTimeout={5000}>
        <ProductProvider>
          <Layout>
            <Component {...pageProps} />
            {isProd || debugEnabled === 'true' ? (
              <Script id="datadog-script" type="text/plain" className="optanon-category-C0002">
                {`
                  (function(h,o,u,n,d) {
                  h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}
                  d=o.createElement(u);d.async=1;d.src=n
                  n=o.getElementsByTagName(u)[0];n.parentNode.insertBefore(d,n)
                  })(window,document,'script','https://www.datadoghq-browser-agent.com/us1/v4/datadog-rum.js','DD_RUM')
                  window.DD_RUM.onReady(function() {
                  window.DD_RUM.init({
                  applicationId: '${process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID}',
                  clientToken: '${process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN}',
                  site: 'datadoghq.com',
                  service:'${process.env.NEXT_PUBLIC_DATADOG_SERVICE}',
                  env:'${process.env.NEXT_PUBLIC_DATADOG_ENV}',
                  // Specify a version number to identify the deployed version of your application in Datadog
                  version: '${version}',
                  sessionSampleRate:100,
                  sessionReplaySampleRate: 10,
                  trackUserInteractions: true,
                  trackResources: true,
                  trackLongTasks: true,
                  defaultPrivacyLevel:'mask-user-input',
                  beforeSend: (event) => {
                    let eventName = event?.view?.name
                    let newPageType = 'static'
                    let plp = document.querySelector('.collection-container')
                    let pdp = document.querySelector('.product-view')
                    let urlPartials = (event?.view?.url).split('${process.env.NEXT_PUBLIC_SITE_REGION}')

                    if (plp) {
                      newPageType = 'plp'
                    }

                    if (pdp) {
                      newPageType = 'pdp'
                    }

                    if (newPageType != 'static' && urlPartials[1]) {
                      let path = urlPartials[1]

                      if (path.endsWith('/')) {
                        path = path.slice(0,-1)
                      }

                      let eventName = '${process.env.NEXT_PUBLIC_SITE_REGION}/'+newPageType+path
                      if (event.view.name != eventName) {
                        console.debug('updating view name to ',eventName)
                        event.view.name = eventName
                      }
                    }
                  }
                })
                  window.DD_RUM.startSessionReplayRecording();
                })
              `}
              </Script>
            ) : null}
            {!devMode && (
              <iframe
                id="iframe-pixel"
                src={`${process.env.NEXT_PUBLIC_BIGCOMMERCE_STORE_URL}/pixel`}
                style={{ visibility: 'hidden', width: '1px', height: '1px', overflow: 'hidden' }}
              ></iframe>
            )}
          </Layout>
        </ProductProvider>
      </ToastProvider>
    </>
  )
}

// if (isClient) {
//   store.dispatch(onLocalLogin())
// }

function MyApp({ Component, pageProps }) {
  // const [lastUrl, setLastUrl] = useState('')
  const router = useRouter()
  const [isClient, setIsClient] = useState(false)
  const { page } = pageProps
  let path = router.asPath
  let smokeTestEnv = process.env.NEXT_PUBLIC_MAINTENANCE_MODE
  let smokeCookie = Cookies.get('smokeTest')
  let query = router.query
  if (query.smokeTest) {
    Cookies.set('smokeTest', true)
  }
  const pathH = publicRuntimeConfig.basePath
  const country = regions.default.find((item) => pathH.includes(item.domain))

  const handleYotpoCookies = () => {
    try {
      const maxIterations = 20
      let iterations = 0

      let intervalId = setInterval(() => {
        if (typeof OnetrustActiveGroups != 'undefined') {
          if (!OnetrustActiveGroups?.includes('C0002')) {
            Object.keys(Cookies.get()).forEach(function (cookieName) {
              // Check if the cookie domain contains 'yotpo'
              if (cookieName.indexOf('yotpo') !== -1) {
                // Remove the cookie
                Cookies.remove(cookieName)
                // console.log('Cookie removed:', cookieName);
                clearInterval(intervalId)
                return
              }
            })
          }
        }

        iterations++
        if (iterations >= maxIterations) {
          clearInterval(intervalId)
          console.log('Interval stopped after maximum iterations')
        }
      }, 3000)
    } catch (error) {
      //console.log('handleYotpoCookies error', error)
    }
  }

  useEffect(() => {
    let smokeTest = Cookies.get('smokeTest')
    window.dataLayer = window.dataLayer || []
    if (smokeTestEnv == 'true' && !smokeTest) {
      router.push('/under-maintenance')
    }

    let currentUrl = window.location.pathname
    if (currentUrl) {
      const nc = currentUrl.replace(/\/$/, '') || '/'
      Cookies.set('lastGtmUrl', nc)
    }

    setIsClient(true)

    if (router?.pathname != '/auth-callback') {
      store.dispatch(onLocalLogin())
    }

    handleYotpoCookies()
  }, [])

  const handleRouteChange = (url) => {
    window.dataLayer = window.dataLayer || []
    // const tagManagerArgs = {
    //   event: 'content_status',
    //   content_updated: true,
    // }
    let currentUrl = window.location.pathname
    let lastGtmUrl = Cookies.get('lastGtmUrl')
    if (currentUrl && lastGtmUrl) {
      currentUrl.replace(/\/$/, '')
      lastGtmUrl.replace(/\/$/, '')
      if (currentUrl != lastGtmUrl) {
        // dataLayer.push(tagManagerArgs)
        Cookies.set('lastGtmUrl', currentUrl)
        // setLastUrl(lastGtmUrl)
      }
    }
  }

  //GTM - Page Views
  useEffect(() => {
    const { userReducer: customer } = store.getState()
    const pathname = router.pathname
    let lastGtmUrl = Cookies.get('lastGtmUrl')

    const dataForGTMEvent = {
      page,
      pageProps,
      customer,
      path,
      lastGtmUrl,
      pathname,
    }

    /*
    Filtering and Sorting always appends to URL e.g 'men/tops/jackets?page=2&filter.ss_variantcolorfamilies=Yellow&sort.total_sold=desc'
    However, certain PLPs will have filters already applied (e.g Women's Best Sellers => us/en-us/best-sellers?filter.custom_gender=Women&filter.custom_gender=Unisex) so therefore want to ensure page view events still fire.
    If the path exists within the previous URL, we know that a user has stayed on the same page and simply applied a sort/filter themselves, which should therefore not trigger additional GTM page view events
    */

    let domainRegex
    if (region?.domain === 'en-us') {
      domainRegex = /^\/us\/en-us/
    } else {
      domainRegex = new RegExp('^/' + region.domain)
    }

    const lastUrlRegexed = lastGtmUrl.replace(domainRegex, '') //Want to remove the domain in order to correctly check path below

    const hasUserFilteredSorted =
      Object.keys(query).some((q) => q.startsWith('filter') || q.startsWith('sort')) &&
      path.includes(lastUrlRegexed) &&
      lastUrlRegexed.length > 1 //Homepage will have the path '/'

    const allFiltersSortsRemoved =
      !Object.keys(query).some((q) => q.startsWith('filter') || q.startsWith('sort')) &&
      path.includes('?') &&
      !/[?][a-zA-Z]/.test(path) //When all filters are removed by user, the '?' will remain in the URL so want to ensure there's no text following this

    const plpPaginationChange = Object.keys(query).some((q) => q.startsWith('page'))

    if (plpPaginationChange || (!hasUserFilteredSorted && !allFiltersSortsRemoved)) {
      setAccountGTM(dataForGTMEvent)
    }
    // if (pageProps?.pageType === 'productDetailPage') {
    //   console.log("router.query - ",router.query)
    //   let variantId = router.query['variant'] || null
    //   productPageGTM(pageProps?.newProductData, variantId) // TODO LATER
    // }
  }, [path, router.pathname])
  //

  const messageHandler = (event) => {
    if (event?.data?.action == 'pixelLoaded') {
      sendPixelData()
    }
  }

  useEffect(() => {}, [router.query])

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  useEffect(() => {
    updateSiteLocale(process.env.NEXT_PUBLIC_SITE_REGION)

    window.addEventListener('message', messageHandler, false)

    /* let siteRegion = region.countryCode == 'GB' ? 'UK' : region.region

    let geoData = `Gore${siteRegion}@${region.hreflang.replace('-', '_')}` */

    //Cookies.set('currentSiteGeoData', geoData)

    //console.debug('geodata set', geoData, region)

    return () => {
      window.removeEventListener('message', messageHandler, false)
    }
  }, [])

  useEffect(() => {
    let previewEu = localStorage.getItem('preview-eu') ? localStorage.getItem('preview-eu') : false

    if (
      process.env.NEXT_PUBLIC_EU_LEGACY_ON &&
      process.env.NEXT_PUBLIC_EU_LEGACY_ON == 'true' &&
      process.env.NEXT_PUBLIC_SITE_REGION != 'us/en-us' &&
      !previewEu
    ) {
      if (removeTrailingSlash(path) != '/coming-soon') {
        localStorage.setItem('previousPath', path)
        router.push('/coming-soon')
      }
    }
  }, [path])

  // check ping session on every page navigation
  if (isClient) {
    const isToken = getLocalstorageItem(5)
    if (isToken) {
      const checkTokenIsValid = getLocalstorageItem(3)
      if (!checkTokenIsValid) {
        localStorage.removeItem('persist:gore-na')
        window.location.reload()
      }
    }
  }

  if (!isClient) {
    return (
      <>
        <Provider store={store}>
          <AppProvider pageProps={{ ...pageProps, renderedFrom: 'server' }} Component={Component} />
        </Provider>
      </>
    )
  } else if (smokeTestEnv == 'true' && !smokeCookie && !path.includes('under-maintenance')) {
    return (
      <>
        <Provider store={store}>
          <PersistGate persistor={persistor} loading={null}>
            {/* <AppProvider pageProps={pageProps} Component={Component} /> */}
          </PersistGate>
        </Provider>
      </>
    )
  } else if (isClient) {
    return (
      <>
        <Provider store={store}>
          <PersistGate persistor={persistor} loading={null}>
            <AppProvider
              pageProps={{ ...pageProps, renderedFrom: 'client' }}
              Component={Component}
            />
          </PersistGate>
        </Provider>
      </>
    )
  }
}

export default MyApp
