import * as types from '@/redux/actionTypes'
import produce from 'immer'

const initialState = {
  cartModal: false,
  searchModal: false,
  loginModal: false,
  countryModal: false,
  breakpoint: null
}

const ApiRecipe = (draft = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_CART_MODAL_SUCCESS:
      return {
        ...draft,
        ...action.payload,
      }
    case types.UPDATE_LOGIN_MODAL_SUCCESS:
      return {
        ...draft,
        ...action.payload,
      }
    case types.UPDATE_COUNTRY_MODAL_SUCCESS:
      return {
        ...draft,
        ...action.payload,
      }
    case types.UPDATE_BREAKPOINT_SUCCESS:
        return {
          ...draft,
          ...action.payload,
        }
    default:
      return draft
  }
}

export const uiReducer = produce(ApiRecipe)
