import axios from 'axios'
import { store } from '@/redux/store/configureStore'
import { onUnauthorizedToken } from '@/redux/actions/userAction'

const fetchClient = () => {
  let cancelToken
  const defaultOptions = {
    // baseURL: 'https://www.rebrand.gorewear.com/us/auth',
    baseURL: process.env.NEXT_PUBLIC_AUTH_URL,
  }
  //console.log('defaultOptions', defaultOptions)
  // Create instance
  const instance = axios.create(defaultOptions)
  instance.interceptors.request.use(
    async function (config) {
      let idToken = null
      const isClient = typeof window !== 'undefined'
      if (isClient) {
        const usersInfo = JSON.parse(localStorage.getItem('persist:gore-na'))
        //console.debug('usersInfo', usersInfo)
        if (usersInfo) {
          idToken = JSON.parse(usersInfo['userReducer'])
        }

        // if (idToken && String(idToken.access_token).length < 12) {
        //   let tokenData = JSON.parse(localStorage.getItem('gore:token') || '{}')
        //   if (tokenData.access_token) {
        //     idToken = tokenData
        //   }
        // }
      }
      config.headers.common['Authorization'] = idToken ? `Bearer ${idToken.access_token}` : ''
      //console.log('config', config)
      // config.headers['access-control-allow-origin'] = `*`
      // config.headers['Access-Control-Allow-Origin'] = `*`
      // config.headers['Content-Type'] = `application/x-www-form-urlencoded`
      // config.headers['origin'] = `http://localhost:3000`
      // config['crossdomain'] = true
      // console.log('config', config)
      return config
    },
    function (error) {
      return Promise.reject(error)
    }
  )

  instance.interceptors.response.use(
    (response) => {
      console.debug('Axios onResponseSuccess', response)
      return response.data
    },
    (error) => {
      console.debug('Axios onResponseError', error)
      console.log(error)
      if (error == "Error: Network Error") {
        store.dispatch(onUnauthorizedToken())
      }
      return Promise.reject(error)
    }
  )

  return instance
}

export const setAuthToken = (token) => {
  if (token) {
    // Apply to every request
    instance.defaults.headers.common['Authorization'] = 'Bearer ' + token
  } else {
    // Delete auth header
    delete instance.defaults.headers.common['Authorization']
  }
}
const AuthClient = fetchClient()
export { AuthClient }
