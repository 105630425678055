import axios from 'axios'
import https from 'https'
import axiosRetry from 'axios-retry'

axiosRetry(axios, {
  retries: 3,
  retryDelay: (retryCount) => {
      console.log(`retry attempt: ${retryCount}`);
      return retryCount * 2000;
  },
  retryCondition: (error) => {
    return axiosRetry.isNetworkOrIdempotentRequestError(error) || error.response.status == 500 || error.response.status == 503 || error.response.status == 429;
  },
});

const fetchClient = () => {
  const defaultOptions = {
    baseURL: process.env.NEXT_PUBLIC_COMMERCE_URL,
    httpsAgent: new https.Agent({
      rejectUnauthorized: false
    })
  }
  
  const instance = axios.create(defaultOptions)

  instance.interceptors.request.use(
    async function (config) {
      let idToken = null
      const isClient = typeof window !== 'undefined'
      if (isClient) {
        const usersInfo = JSON.parse(localStorage.getItem('persist:gore-na'))
        if (usersInfo) {
          idToken = JSON.parse(usersInfo['userReducer'])
        }
      }
      config.headers.common['Authorization'] = (idToken && idToken.access_token) ? `Bearer ${idToken.access_token}` : ''
      config['withCredentials'] = true
      return config
    },
    function (error) {
      return Promise.reject(error)
    }
  )

  instance.interceptors.response.use(
    (response) => {
      return response.data
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  return instance
}

const CartClient = fetchClient()

export { CartClient }
