import { getCategoriesNames } from '@/lib/helpers'
import { getCurrentRegion } from '@/lib/region-helper'
import { getProductReviewCount, getProductBySku } from './product-helper'

//GTM helpers
const additionalParamInfo = (product) => {
  if (typeof window !== 'undefined') {
    const customFields = product.custom_fields
    const region = getCurrentRegion()
    const gender = customFields.find((customField) => customField.name == 'gender')?.en_trans || ''
    const sport =
      customFields.find((customField) => customField.name == 'sport_activity')?.en_trans || ''
    const categoryIds = product.categories || product.categoriesId
    const categories = getCategoriesNames(categoryIds).split(' / ')
    return {
      gender,
      sport,
      region,
      categories,
    }
  }
}

//Product Interaction GTM
export const addToCartGTM = async (product) => {
  const { gender, sport, region, categories } = additionalParamInfo(product)
  dataLayer.push({ ecommerce: null }) // Clear previous ecommerce object
  dataLayer.push({
    event: 'addToCart',
    ecommerce: {
      currency: region.isoCode,
      items: [
        {
          name: product?.name,
          id: product?.sku,
          quantity: 1,
          price: product?.price,
          item_category: gender,
          item_category2: categories[1],
          item_category3: categories[2],
          item_category4: product?.fabricTechnologyTitle,
          brand: 'GOREWEAR',
          variant: product?.variantSku,
          item_regular_price: product.regPrice,
          item_size: product.size,
          item_rating: product.rating,
          item_review_count: product.reviewCount,
          item_gender: gender,
          item_color: product?.color,
          item_sport: sport,
          item_upc: product?.upc,
          item_stock: product?.availability,
        },
      ],
    },
  })
}

export const removeFromCartGTM = async (product) => {
  const { gender, sport, region, categories } = additionalParamInfo(product)
  const reviews = getProductReviewCount(product?.id || product?.entityId)

  if (reviews) {
    dataLayer.push({ ecommerce: null }) // Clear previous ecommerce object
    dataLayer.push({
      event: 'remove_from_cart',
      ecommerce: {
        currency: region.isoCode,
        items: [
          {
            name: product?.name,
            id: product?.sku?.match(/\d{6}/g)[0],
            quantity: 1,
            price: product.sale_price ? product.sale_price : product.price,
            item_category: gender?.value,
            item_category2: categories[1],
            item_category3: categories[2],
            item_category4: product?.fabricTechnology?.title.replace(/[^a-zA-Z0-9 ]/g, '')
              ? product?.fabricTechnology?.title.replace(/[^a-zA-Z0-9 ]/g, '')
              : '',
            brand: 'GOREWEAR',
            variant: product.sku,
            item_regular_price: product.price,
            item_size: product.itemSize.label,
            item_rating: reviews?.score ? String(reviews?.score) : '0',
            item_review_count: reviews?.total_count ? String(reviews?.total_count) : '0',
            item_gender: gender.value,
            item_color: product.itemcolor.label,
            item_sport: sport.value,
            item_upc: product.upc,
            item_stock: product.availability,
          },
        ],
      },
    })
  }
}

export const quantityChangeGTM = (product, quantity, quantityChange) => {
  const { region } = additionalParamInfo(product)

  const previousEvent = [...dataLayer].findLast((x) => {
    return x.event === 'addToCart' || x.event === 'remove_from_cart'
  })

  const quantityChangeProd = previousEvent.ecommerce.items[0]

  if (quantityChangeProd) {
    dataLayer.push({ ecommerce: null }) // Clear previous ecommerce object
    dataLayer.push({
      event: quantityChange === 'add' ? 'addToCart' : 'remove_from_cart',
      ecommerce: {
        currency: region.isoCode,
        items: [
          {
            name: quantityChangeProd.name,
            id: quantityChangeProd.id,
            quantity: quantity,
            quantity_change:
              quantityChange === 'add'
                ? quantity - quantityChangeProd.quantity
                : quantityChangeProd.quantity - quantity,
            price: quantityChangeProd.price,
            item_category: quantityChangeProd.item_category,
            item_category2: quantityChangeProd.item_category2,
            item_category3: quantityChangeProd.item_category3,
            item_category4: quantityChangeProd.item_category4,
            brand: 'GOREWEAR',
            variant: quantityChangeProd.variant,
            item_regular_price: quantityChangeProd.item_regular_price,
            item_size: quantityChangeProd.item_size,
            item_rating: quantityChangeProd.item_rating,
            item_review_count: quantityChangeProd.item_review_count,
            item_gender: quantityChangeProd.item_gender,
            item_color: quantityChangeProd.item_color,
            item_sport: quantityChangeProd.item_sport,
            item_upc: quantityChangeProd.item_upc,
            item_stock: product.availability,
          },
        ],
      },
    })
  }
}

export const productPageGTM = async (product, variant, priceData) => {
  const { gender, sport, region, categories } = additionalParamInfo(product)
  const reviews = product?.reviews || {}
  dataLayer.push({ ecommerce: null }) // Clear previous ecommerce object
  if (product && categories && region && reviews) {
    let existingViewItem = dataLayer.filter((item) => item.event == 'view_item')
    let lastViewItem = existingViewItem[existingViewItem.length - 1]?.ecommerce?.items[0].variant
    if (lastViewItem && lastViewItem == variant.sku) {
      // SKIP DUPLICATE EVENTS
    } else {
      dataLayer.push({
        event: 'view_item',
        ecommerce: {
          currency: region.isoCode,
          items: [
            // Array of Item Objects for each product removed
            {
              name: product?.defaultName,
              id: product?.sku,
              quantity: 1,
              price: priceData.salePrice ? priceData.salePrice : priceData.regularPrice,
              item_category: gender || '',
              item_category2: categories[1],
              item_category3: categories[2],
              item_category4: product?.fabricTechnology?.title
                ? product?.fabricTechnology?.title.replace(/[^a-zA-Z0-9 ]/g, '')
                : '',
              brand: 'GOREWEAR',
              variant: variant.sku,
              discount: Number((priceData.regularPrice - priceData.salePrice).toFixed(2)),
              item_regular_price: priceData.regularPrice,
              item_rating: reviews?.averageScore ? String(reviews?.averageScore) : '0',
              item_review_count: reviews?.totalReview ? String(reviews?.totalReview) : '0',
              item_gender: gender || '',
              item_sport: sport || '',
              item_upc: variant.upc,
              item_stock: product.availability,
              item_color:
                variant?.option_values.find((item) => item.option_display_name == 'Color')?.label ||
                '',
              item_size:
                variant?.option_values.find((item) => item.option_display_name == 'Size')?.label ||
                '',
            },
          ],
        },
      })
    }
  }
}

export const selectItemGTM = async (productPLP) => {
  if (productPLP) {
    const product = productPLP
    const { gender, region, categories } = additionalParamInfo(product)

    const fabricTech = product?.fabric_tech || ''

    dataLayer.push({ ecommerce: null }) // Clear previous ecommerce object
    if (product && categories) {
      dataLayer.push({
        event: 'select_item',
        ecommerce: {
          currency: region?.isoCode,
          items: [
            {
              name: product?.enProductName,
              id: product?.sku,
              quantity: 1,
              price:
                product?.selectedVariant?.salePrice != 0
                  ? product?.selectedVariant?.salePrice
                  : product?.selectedVariant?.price,
              item_category: gender || '',
              item_category2: categories[1],
              item_category3: categories[2],
              item_category4: fabricTech,
              brand: 'GOREWEAR',
              variant: product?.sku,
              item_regular_price: product?.selectedVariant?.price,
              item_rating: product?.reviewsCount?.score
                ? String(product?.reviewsCount?.score)
                : '0',
              item_review_count: product?.reviewsCount?.total_count
                ? String(product?.reviewsCount?.total_count)
                : '0',
              item_gender: gender || '',
              item_sport: categories[1],
            },
          ],
        },
      })
    }
  }
}

export const addToWishlistGTM = (product) => {
  const { region, categories } = additionalParamInfo(product)

  dataLayer.push({ ecommerce: null }) // Clear previous ecommerce object
  dataLayer.push({
    event: 'add_to_wishlist',
    ecommerce: {
      currency: region.isoCode,
      items: [
        {
          name: product?.name,
          id: product?.sku,
          quantity: 1,
          price: product.sale_price ? product.sale_price : product.price,
          item_category: categories[0],
          item_category2: categories[1],
          item_category3: categories[2],
          item_category4: product?.custom_fields?.find((field) => field.name === 'fabric_tech')
            ?.value
            ? product?.custom_fields
                ?.find((field) => field.name === 'fabric_tech')
                ?.value.replace(/[^a-zA-Z0-9 ]/g, '')
            : '',
          brand: 'GOREWEAR',
          item_regular_price: product.price ? product.price : product.retail_price,
          item_rating: product.reviews_rating_sum ? product.reviews_rating_sum : '',
          item_review_count: product.reviews_count ? product.reviews_rating_sum : '',
          item_gender: categories[0],
          item_sport: categories[1],
          item_upc: product.upc ? product.upc : '',
          item_stock: product.availability ? product.availability : '',
        },
      ],
    },
  })
}

export const categoryPageGTM = async (products, reviews) => {
  if (typeof window !== 'undefined' && window.dataLayer && products) {
    const productsToPush = []

    for (const product of products) {
      const categoryPageEvents = [...dataLayer].filter((gtmEvent) => {
        return gtmEvent.event === 'categoryPage'
      })

      const pushedProductIds = categoryPageEvents.flatMap((page) =>
        page.ecommerce.items.map((item) => item.id)
      )

      let productSku = product.sku

      if (!pushedProductIds.includes(productSku)) {
        const productObj = product
        const { gender, sport, categories } = additionalParamInfo(productObj)

        productsToPush.push({
          name: productObj.enProductName,
          id: productObj.sku,
          quantity: 1,
          price:
            productObj?.selectedVariant?.salePrice != 0
              ? productObj?.selectedVariant?.salePrice
              : productObj?.selectedVariant?.price,
          item_category: gender || '',
          item_category2: categories[1],
          item_category3: categories[2],
          item_category4: productObj.fabric_tech || '',
          brand: 'GOREWEAR',
          variant: productObj.sku,
          item_regular_price: productObj?.selectedVariant?.price,
          item_rating: productObj?.reviewsCount?.score
            ? String(productObj?.reviewsCount?.score)
            : '0',
          item_review_count: productObj?.reviewsCount?.total_count
            ? String(productObj?.reviewsCount?.total_count)
            : '0',
          item_gender: gender || '',
          item_sport: sport || '',
        })
      }
    }

    if (productsToPush.length) {
      const region = getCurrentRegion()

      dataLayer.push({ ecommerce: null }) // Clear previous ecommerce object
      dataLayer.push({
        event: 'categoryPage',
        ecommerce: {
          currency: region.isoCode,
          items: productsToPush,
        },
      })
    }
  }
}

//Page Views GTM
export const setAccountGTM = (account) => {
  ////////Additional data to add into setAccount object - detecting gender for different languages
  let currentUrl = window.location.pathname.toLowerCase()
  if (window?.location?.search) {
    currentUrl += window.location.search.toLowerCase()
  }

  let pageSection

  const menLanguageVariants = ['men', 'herren', 'hommes', 'uomo', 'hombre', 'hombres']
  const womensLanguageVariants = ['women', 'damen', 'femmes', 'donna', 'donne', 'muijeres', 'mujer']

  for (let i = 0; i <= womensLanguageVariants.length; i++) {
    if (currentUrl.includes(womensLanguageVariants[i])) {
      pageSection = 'womens'
      break
    } else if (currentUrl.includes(menLanguageVariants[i])) {
      pageSection = 'mens'
      break
    } else {
      pageSection = 'unisex'
    }
  }

  const region = getCurrentRegion()
  //////////

  const setAccountData = {
    event: 'setaccount',
    page_location: currentUrl,
    page_title: account?.page?.title ? account.page.title : '',
    page_referrer: account?.lastGtmUrl ? account.lastGtmUrl : '',
    user_id: account?.customer?.isAuthenticated ? account.customer.profile?.id : null,
    site_lang: region.localeDomain,
    site_loc_lang: region.hreflang,
    site_locale: region.storeSource,
    traffic_type: '',
    logged_status: account?.customer?.isAuthenticated ? 'true' : 'false',
    membership_type: account?.customer?.membership?.category ?? '',
    page_section: pageSection,
    page_type: '',
    page_category: '',
    page_sub_category: '',
  }

  if (account && !account.customer.isAuthenticated) {
    delete setAccountData.user_id
  }

  if (account.pathname === '/sign-up') {
    const signUpData = {
      page_title: 'Sign Up',
      page_type: 'Sign Up',
      page_category: 'Sign Up',
    }
    Object.assign(setAccountData, signUpData)
  }

  if (account.pageProps?.page?.slug === 'content-page') {
    const collData = {
      page_title: account.pageProps?.contentPage?.title,
      page_type: 'Collections Page',
      page_category: 'Collections',
    }
    Object.assign(setAccountData, collData)
  }

  if (account?.page?.slug === 'homepage') {
    const homepageData = {
      page_title: 'Homepage',
      page_type: 'homepage',
      page_category: 'homepage',
    }
    Object.assign(setAccountData, homepageData)
  }

  if (account.pageProps?.pageType === 'productListPage') {
    const plpData = {
      page_title: account.pageProps?.category?.name || 'Collection',
      page_type: 'Product List Page',
      page_category: 'Product List Page',
    }
    Object.assign(setAccountData, plpData)
  }

  if (account.pageProps?.pageType === 'productDetailPage') {
    const pdpData = {
      page_title: account.pageProps?.product?.name,
      page_type: 'Product Detail Page',
      page_category: 'Product Detail Page',
    }
    Object.assign(setAccountData, pdpData)
  }

  if (account.pathname === '/404') {
    const errData = {
      page_title: '404 - Page Not Found',
      page_type: '/404',
      page_category: 'Error Page',
      page_section: '',
    }
    Object.assign(setAccountData, errData)
  }

  dataLayer.push(setAccountData)
}

//Event Tracking GTM
export const siteSearchGTM = (search) => {
  dataLayer.push({
    event: 'view_search_results',
    search_term: search,
  })
}

export const videoEngagementGTM = (videoInfo, videoEvent) => {
  const { video } = videoInfo

  switch (videoEvent) {
    case 'video_start':
      dataLayer.push({
        event: 'video_start',
        video_title: video?.title ? video?.title : '',
        video_url: video?.url ? video?.url : video?.video?.url,
        video_provider: '',
        video_duration: videoInfo.duration,
        video_current_time: '0',
        video_percent: '0',
        visible: true,
      })
      break
    case 'video_progress':
      dataLayer.push({
        event: 'video_progress',
        video_title: video?.title ? video?.title : '',
        video_url: video?.url ? video?.url : video?.video?.url,
        video_provider: '',
        video_duration: videoInfo.duration,
        video_current_time: videoInfo.played,
        video_percent: videoInfo.percentage,
        visible: true,
      })
      break
    case 'video_complete':
      dataLayer.push({
        event: 'video_complete',
        video_title: video?.title ? video?.title : '',
        video_url: video?.url ? video?.url : video?.video?.url,
        video_provider: '',
        video_duration: videoInfo.duration,
        video_current_time: videoInfo.played,
        video_percent: 100,
        visible: true,
      })
      break
  }
}

export const itemSortGTM = (sort) => {
  dataLayer.push({
    event: 'item_sort',
    item_sort: sort,
  })
}

export const itemFilterGTM = (filter) => {
  dataLayer.push({
    event: 'item_filter',
    filter_name: filter.type,
    filter_value: filter.value,
  })
}

export const pdpThumbnailCarouselGTM = (product, imgIndex) => {
  const region = getCurrentRegion()

  dataLayer.push({
    event: 'pdp_thumbnail_carousel',
    item_name: product.name,
    currency: region.isoCode,
    value: product.price ? product.price : 0,
  })
}

export const pdpThumbnailZoomGTM = (product, imgIndex) => {
  const region = getCurrentRegion()

  dataLayer.push({
    event: 'pdp_thumbnail_zoom',
    item_name: product.name,
    currency: region.isoCode,
    value: product.price ? product.price : 0,
  })
}

export const writeReviewGTM = (product) => {
  const region = getCurrentRegion()

  dataLayer.push({
    event: 'write_review',
    items: product.name,
    currency: region.isoCode,
    value: product.price ? product.price : 0,
  })
}

export const submitReviewGTM = (product) => {
  const region = getCurrentRegion()

  dataLayer.push({
    event: 'submit_review',
    item_name: product.name,
    currency: region.isoCode,
    value: '',
  })
}

export const readReviewsGTM = (product) => {
  const region = getCurrentRegion()

  dataLayer.push({
    event: 'read_reviews',
    items: product.name,
    currency: region.isoCode,
    value: product.price ? product.price : 0,
  })
}

export const accountCreateGTM = () => {
  dataLayer.push({
    event: 'account_create',
    location_id: window.location.href,
  })
}

export const contentClickGTM = (text, url) => {
  dataLayer.push({
    event: 'content_click',
    link_text: text,
    link_url: url,
    location_id: window.location.href,
  })
}

export const socialClickGTM = (social) => {
  dataLayer.push({
    event: 'social_click',
    link_text: social.title,
    link_url: social.url,
    location_id: window.location.href,
  })
}

export const navClickGTM = (url, title, menu_level, menu) => {
  const menuLevelPartials = menu_level?.split('::')
  const menuLevel = menuLevelPartials?.map((part) => part.trim()).join('::')

  dataLayer.push({
    event: 'nav_click',
    link_text: title,
    link_url: url,
    menu_level: menuLevel?.toLowerCase()?.replace(/[-\s]/g, '_'),
    menu: menu,
  })
}

export const ctaClickGTM = (text, url) => {
  dataLayer.push({
    event: 'cta_click',
    link_text: text,
    link_url: url,
    location_id: window.location.href,
  })
}

export const searchResultsCategoryGTM = (category) => {
  dataLayer.push({
    event: 'search_results_category',
    search_result_category_selection: category,
  })
}

export const errorEventGTM = (type, message) => {
  if (typeof window !== 'undefined' && window.datalayer) {
    dataLayer.push({
      event: 'error',
      error_type: type,
      error_message: message,
    })
  }
}

export const newsletterSignupGTM = () => {
  dataLayer.push({
    event: 'newsletter_signup',
    location_id: window.location.href,
  })
}

export const viewCartGTM = (cartValue, cartItems) => {
  const region = getCurrentRegion()
  if (typeof window !== 'undefined' && window.dataLayer && cartValue && cartItems) {
    dataLayer.push({ ecommerce: null }) // Clear previous ecommerce object
    dataLayer.push({
      event: 'view_cart',
      ecommerce: {
        currency: region.isoCode,
        value: cartValue,
        items: cartItems,
      },
    })
  }
}

export const accountLoginGTM = (location) => {
  dataLayer.push({
    event: 'account_login',
    location_id: location,
  })
}

export const prepareCouponDataGTM = (couponArray) => {
  if (couponArray?.length < 1) {
    return ''
  }

  return couponArray.map((coupon) => coupon?.name)?.join('::')
}
