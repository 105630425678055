import { CartClient } from '@/lib/cart-client'
import { getUserLocal } from '@/lib/helpers'
import { getRegion } from '@/lib/region-helper'

export const getAllProducts = async (params = {}, abortSignal = null) => {
  let newParams = {
    params: { ...params },
  }

  if (abortSignal != null) {
    newParams = {
      ...newParams,
      signal: abortSignal,
    }
  }

  try {
    const { products } = await CartClient.get(`/products`, newParams)
    return products
  } catch (err) {
    // Handle Error Here
    if(err?.response?.status || err?.response?.statusText) {
      console.log(`getAllProducts err ${err?.response?.status} ${err?.response?.statusText}`)
    }
    else {
      console.log(err)
    }
    // throw err
  }
}

export const getProduct = async (productId, params = {}, abortSignal = null) => {
  try {
    let newParams = {
      params: { ...params, user_locale_cache: getUserLocal() },
    }

    if (abortSignal != null) {
      newParams = {
        ...newParams,
        signal: abortSignal,
      }
    }

    const { product } = await CartClient.get(`/products/${productId}`, newParams)
    return product
  } catch (err) {
    // Handle Error Here
    if(err?.response?.status || err?.response?.statusText) {
      console.log(`getProduct err ${err?.response?.status} ${err?.response?.statusText}`)
    }
    else {
      console.log(err)
    }
    // throw err
  }
}

export const getProductRevalidate = async (productId, params = {}, abortSignal = null) => {
  try {
    const timestamp = Date.now();
    let newParams = {
      params: { ...params, user_locale_cache: getUserLocal() },
    }

    if (abortSignal != null) {
      newParams = {
        ...newParams,
        signal: abortSignal,
      }
    }
    const { product } = await CartClient.get(`/products/${productId}?${timestamp}`, newParams)
    return product
  } catch (err) {
    // Handle Error Here
    if(err?.response?.status || err?.response?.statusText) {
      console.log(`getProduct err ${err?.response?.status} ${err?.response?.statusText}`)
    }
    else {
      console.log(err)
    }
    // throw err
  }
}

export const getProductMetafields = async (ProductId, params = {}) => {
  try {
    const { metafields } = await CartClient.get(`/products/${ProductId}/metafields`, {
      params: params,
    })

    return metafields
  } catch (err) {
    // Handle Error Here
    if(err?.response?.status || err?.response?.statusText) {
      console.log(`getProductMetafields err ${err?.response?.status} ${err?.response?.statusText}`)
    }
    else {
      console.log(err)
    }
    // throw err
  }
}

export const getProductMetafieldsRevalidate = async (ProductId, params = {}) => {
  try {
    const timestamp = Date.now();

    const { metafields } = await CartClient.get(`/products/${ProductId}/metafields?${timestamp}`, {
      params: params,
    })

    return metafields
  } catch (err) {
    // Handle Error Here
    if(err?.response?.status || err?.response?.statusText) {
      console.log(`getProductMetafields err ${err?.response?.status} ${err?.response?.statusText}`)
    }
    else {
      console.log(err)
    }
    // throw err
  }
}

export const getProductImages = async (ProductId, params = {}) => {
  try {
    const { images } = await CartClient.get(`/products/${ProductId}/images`, {
      params: params,
    })
    return images
  } catch (err) {
    if(err?.response?.status || err?.response?.statusText) {
      console.error(`getProductImages err ${err?.response?.status} ${err?.response?.statusText}`)
    }
    else {
      console.log(err)
    }
    // throw err
  }
}

export const getProductImagesEdges = async (productId) => {
  try {
    const result = await CartClient.get(`/products/images/graphql/${productId}`)
    return result
  } catch (error) {
    if(error?.response?.status || error?.response?.statusText) {
      console.log(`getProductImages err ${error?.response?.status} ${error?.response?.statusText}`)
    }
    else {
      console.log(`getProductImages err ${error?.message}`)
    }
    // throw error
  }
}

export const getVariantById = async (productId, variantId, params = {}) => {
  try {
    const { variant } = await CartClient.get(`/products/${productId}/variants/${variantId}`, params)
    return variant
  } catch (error) {
    if(error?.response?.status || error?.response?.statusText) {
      console.log(`getVariantMetafields err ${error?.response?.status} ${error?.response?.statusText}`)
    }
    else {
      console.log(error)
    }
    // throw error
  }
}

export const getVariantMetafields = async (productId, variantId) => {
  try {
    const { metafields } = await CartClient.get(
      `/products/${productId}/variants/${variantId}/metafields`
    )
    return metafields.data
  } catch (error) {
    if(error?.response?.status || error?.response?.statusText) {
      console.log(`getVariantMetafields err ${error?.response?.status} ${error?.response?.statusText}`)
    }
    else {
      console.log(error)
    }
    // throw error
  }
}

export const getVariantMetafieldsRevalidate = async (productId, variantId) => {
  try {
    const timestamp = Date.now()
    const { metafields } = await CartClient.get(
      `/products/${productId}/variants/${variantId}/metafields?${timestamp}`
    )
    return metafields.data
  } catch (error) {
    if(error?.response?.status || error?.response?.statusText) {
      console.log(`getVariantMetafields err ${error?.response?.status} ${error?.response?.statusText}`)
    }
    else {
      console.log(error)
    }
    // throw error
  }
}

export const getLowestPricedVariantColor = async (variants, color, country) => {
  try {
    if (variants.length) {
      let variantIds = []

      let selectedVariants = variants.filter((variant) => {
        let colorOption = variant.option_values.find((optionValue) => {
          return optionValue.option_display_name === 'Color'
        })

        if (colorOption && colorOption.label.toLowerCase() == color.toLowerCase()) {
          variantIds.push(variant.id)

          return variant
        }
      })

      const { data } = await CartClient.post(`/products/variants/lowestPrice`, {
        variants: variantIds,
        isoCode: country.isoCode.toLowerCase(),
        productId: selectedVariants[0].product_id,
      })
    }
  } catch (error) {
    if(error?.response?.status || error?.response?.statusText) {
      console.log(`getLowestPricedVariantColor err ${error?.response?.status} ${error?.response?.statusText}`)
    }
    else {
      console.log(error)
    }
    // throw error
  }
}

export const getVariantsMetafields = async (variants, productId) => {
  try {
    if (variants.length) {
      let variantIds = variants.map((variant) => variant.id)

      const data = await CartClient.post(`/variants/metafields`, {
        variantIds: variantIds,
        productId: productId,
      })

      return data
    }
  } catch (error) {
    if(error?.response?.status || error?.response?.statusText) {
      console.log(`getVariantsMetafields err ${error?.response?.status} ${error?.response?.statusText}`)
    }
    else {
      console.log(error)
    }
    // throw error
  }
}

export const trackViewedProduct = async (product) => {
  const region = getRegion(process.env.NEXT_PUBLIC_SITE_REGION)
  try {
    await CartClient.post('/products/events/viewed', { product, user_locale_cache: region.domain })
  } catch (error) {
    if(error?.response?.status || error?.response?.statusText) {
      console.log(`trackViewedProduct err ${error?.response?.status} ${error?.response?.statusText}`)
    }
    else {
      console.log(error)
    }
    // throw error
  }
}

export const getProductsWithMetafields = async (params = {}) => {
  try {
    console.log('mt===>', params)
    const data = await CartClient.post(`/localized/products`, params)

    return data?.products
  } catch (error) {
    if(error?.response?.status || error?.response?.statusText) {
      console.log(`getProductsWithMetafields err ${error?.response?.status} ${error?.response?.statusText}`)
    }
    else {
      console.log(error)
    }
    // throw error
  }
}

export const getAllVariants = async (params ={}) => {
  try {

    const data = await CartClient.get('/variants', { params: params})

    if (data?.success) {
      return data?.data
    }

    return []
  } catch (error) {
    if(error?.response?.status || error?.response?.statusText) {
      console.log(`getAllVariants err ${error?.response?.status} ${error?.response?.statusText}`)
    }
    else {
      console.log(error)
    }
    // throw error
  }
}

export const getAllVariantsRevalidate = async (params ={}) => {
  try {
    const timestamp = Date.now()
    const data = await CartClient.get(`/variants?${timestamp}`, { params: params})

    if (data?.success) {
      return data?.data
    }

    return []
  } catch (error) {
    if(error?.response?.status || error?.response?.statusText) {
      console.log(`getAllVariants err ${error?.response?.status} ${error?.response?.statusText}`)
    }
    else {
      console.log(error)
    }
    // throw error
  }
}
