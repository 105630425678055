import * as types from '@/redux/actionTypes'

export const updateCartModal = cartModal => async dispatch => {

  await dispatch({
    type: types.UPDATE_CART_MODAL_SUCCESS,
    payload: {
      cartModal: cartModal
    }
  })
}

export const updateLoginModal = loginModal => async dispatch => {

  await dispatch({
    type: types.UPDATE_LOGIN_MODAL_SUCCESS,
    payload: {
      loginModal: loginModal
    }
  })
}

export const updateCountryModal = countryModal => async dispatch => {

  await dispatch({
    type: types.UPDATE_COUNTRY_MODAL_SUCCESS,
    payload: {
      countryModal: countryModal
    }
  })
}
