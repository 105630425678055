exports.default = [
  {
    "domain": "at-de",
    "localeDomain": "de",
    "countryName": "Austria",
    "currencySymbol": "€",
    "isoCode": "EUR",
    "locale": "de-AT",
    "localePrefix": "de",
    "flag": "flag-icon-at",
    "region": "DE",
    "useFlag": true,
    "storeSource": "EU",
    "countryCode": "AT",
    "hreflang": "de-AT",
    "countrySeoName": "ÖSTERREICH"
  },
  {
    "domain": "be-en",
    "localeDomain": "en",
    "countryName": "Belgium",
    "currencySymbol": "€",
    "isoCode": "EUR",
    "locale": "en-BE",
    "localePrefix": "en",
    "flag": "flag-icon-be",
    "region": "EU",
    "useFlag": true,
    "storeSource": "EU",
    "countryCode": "BE",
    "hreflang": "en-BE",
    "countrySeoName": "BELGIUM"
  },
  {
    "domain": "be-fr",
    "localeDomain": "be_fr",
    "countryName": "Belgium",
    "currencySymbol": "€",
    "isoCode": "EUR",
    "locale": "fr-BE",
    "localePrefix": "fr",
    "flag": "flag-icon-be",
    "region": "EU",
    "useFlag": false,
    "storeSource": "EU",
    "countryCode": "BE",
    "hreflang": "fr-BE",
    "countrySeoName": "BELGIQUE"
  },
  {
    "domain": "dk-en",
    "localeDomain": "en",
    "countryName": "Denmark",
    "currencySymbol": "KR",
    "isoCode": "DKK",
    "locale": "en-DK",
    "localePrefix": "en",
    "flag": "flag-icon-dk",
    "region": "EU",
    "useFlag": true,
    "storeSource": "EU",
    "countryCode": "DK",
    "hreflang": "en-DK",
    "countrySeoName": "DENMARK"
  },
  {
    "domain": "fi-en",
    "localeDomain": "en",
    "countryName": "Finland",
    "currencySymbol": "€",
    "isoCode": "FIN",
    "locale": "en-FI",
    "localePrefix": "en",
    "flag": "flag-icon-fi",
    "region": "EU",
    "useFlag": true,
    "storeSource": "EU",
    "countryCode": "FI",
    "hreflang": "en-FI",
    "countrySeoName": "FINLAND"
  },
  {
    "domain": "fr-fr",
    "localeDomain": "fr",
    "countryName": "France",
    "currencySymbol": "€",
    "isoCode": "EUR",
    "locale": "fr-FR",
    "localePrefix": "fr",
    "flag": "flag-icon-fr",
    "region": "EU",
    "useFlag": true,
    "storeSource": "EU",
    "countryCode": "FR",
    "hreflang": "fr-FR",
    "countrySeoName": "FRANCE"
  },
  {
    "domain": "de-de",
    "localeDomain": "de",
    "countryName": "Germany",
    "currencySymbol": "€",
    "isoCode": "EUR",
    "locale": "de-DE",
    "localePrefix": "de",
    "flag": "flag-icon-de",
    "region": "DE",
    "useFlag": true,
    "storeSource": "EU",
    "countryCode": "DE",
    "hreflang": "de-DE",
    "countrySeoName": "DEUTSCHLAND"
  },
  {
    "domain": "ie-en",
    "localeDomain" : "ie",
    "countryName": "Ireland",
    "currencySymbol": "€",
    "isoCode": "EUR",
    "locale": "en-IE",
    "localePrefix": "en",
    "flag": "flag-icon-ie",
    "region": "EU",
    "useFlag": true,
    "storeSource": "EU",
    "countryCode": "IE",
    "hreflang": "en-IE",
    "countrySeoName": "IRELAND"
  },
  {
    "domain": "it-it",
    "localeDomain": "it",
    "countryName": "Italy",
    "currencySymbol": "€",
    "isoCode": "EUR",
    "locale": "it-IT",
    "localePrefix": "it",
    "flag": "flag-icon-it",
    "region": "EU",
    "useFlag": true,
    "storeSource": "EU",
    "countryCode": "IT",
    "hreflang": "it-IT",
    "countrySeoName": "ITALIA"
  },
  {
    "domain": "lu-fr",
    "localeDomain": "lu",
    "countryName": "Luxembourg",
    "currencySymbol": "€",
    "isoCode": "EUR",
    "locale": "fr-LU",
    "localePrefix": "fr",
    "flag": "flag-icon-lu",
    "region": "EU",
    "useFlag": true,
    "storeSource": "EU",
    "countryCode": "LU",
    "hreflang": "fr-LU",
    "countrySeoName": "LUXEMBOURG"
  },
  {
    "domain": "nl-en",
    "localeDomain": "en",
    "countryName": "Netherlands",
    "currencySymbol": "€",
    "isoCode": "EUR",
    "locale": "en-NL",
    "localePrefix": "en",
    "flag": "flag-icon-nl",
    "region": "EU",
    "useFlag": true,
    "storeSource": "EU",
    "countryCode": "NL",
    "hreflang": "en-NL",
    "countrySeoName": "NETHERLANDS"
  },
  {
    "domain": "no-en",
    "localeDomain": "en",
    "countryName": "Norway",
    "currencySymbol": "KR",
    "isoCode": "NOK",
    "locale": "en-NO",
    "localePrefix": "en",
    "flag": "flag-icon-no",
    "region": "EU",
    "useFlag": true,
    "storeSource": "EU",
    "countryCode": "NO",
    "hreflang": "en-NO",
    "countrySeoName": "NORWAY"
  },
  {
    "domain": "pt-en",
    "localeDomain": "en",
    "countryName": "Portugal",
    "currencySymbol": "€",
    "isoCode": "EUR",
    "locale": "en-PT",
    "localePrefix": "en",
    "flag": "flag-icon-pt",
    "region": "EU",
    "useFlag": true,
    "storeSource": "EU",
    "countryCode": "PT",
    "hreflang": "en-PT",
    "countrySeoName": "PORTUGAL"
  },
  {
    "domain": "es-es",
    "localeDomain": "es",
    "countryName": "Spain",
    "currencySymbol": "€",
    "isoCode": "EUR",
    "locale": "es-ES",
    "localePrefix": "es",
    "flag": "flag-icon-es",
    "region": "EU",
    "useFlag": true,
    "storeSource": "EU",
    "countryCode": "ES",
    "hreflang": "es-ES",
    "countrySeoName": "ESPAÑA"
  },
  {
    "domain": "ch-it",
    "localeDomain": "ch_it",
    "countryName": "Switzerland",
    "currencySymbol": "CHF",
    "isoCode": "CHF",
    "locale": "it-CH",
    "localePrefix": "it",
    "flag": "flag-icon-ch",
    "region": "DE",
    "useFlag": true,
    "storeSource": "EU",
    "countryCode": "CH",
    "hreflang": "it-CH",
    "countrySeoName": "SVIZZERA"
  },
  {
    "domain": "ch-de",
    "localeDomain": "de",
    "countryName": "Switzerland",
    "currencySymbol": "CHF",
    "isoCode": "CHF",
    "locale": "de-CH",
    "localePrefix": "de",
    "flag": "flag-icon-ch",
    "region": "DE",
    "useFlag": false,
    "storeSource": "EU",
    "countryCode": "CH",
    "hreflang": "de-CH",
    "countrySeoName": "SCHWEIZ"
  },
  {
    "domain": "ch-fr",
    "localeDomain": "ch_fr",
    "countryName": "Switzerland",
    "currencySymbol": "CHF",
    "isoCode": "CHF",
    "locale": "fr-CH",
    "localePrefix": "fr",
    "flag": "flag-icon-ch",
    "region": "DE",
    "useFlag": false,
    "storeSource": "EU",
    "countryCode": "CH",
    "hreflang": "fr-CH",
    "countrySeoName": "SUISSE"
  },
  {
    "domain": "se-en",
    "localeDomain": "en",
    "countryName": "Sweden",
    "currencySymbol": "KR",
    "isoCode": "SEK",
    "locale": "en-SE",
    "localePrefix": "en",
    "flag": "flag-icon-se",
    "region": "EU",
    "useFlag": true,
    "storeSource": "EU",
    "countryCode": "SE",
    "hreflang": "en-SE",
    "countrySeoName": "SWEDEN"
  },
  {
    "domain": "en-uk",
    "localeDomain" : "uk",
    "countryName": "United Kingdom",
    "currencySymbol": "£",
    "isoCode": "GBP",
    "locale": "en-GB",
    "localePrefix": "en",
    "flag": "flag-icon-gb",
    "region": "EU",
    "useFlag": true,
    "storeSource": "EU",
    "countryCode": "GB",
    "hreflang": "en-GB",
    "countrySeoName": "UK"
  },
  {
    "domain": "en-us",
    "localeDomain":"en",
    "countryName": "United States",
    "currencySymbol": "$",
    "isoCode": "USD",
    "locale": "en-US",
    "localePrefix": "en",
    "flag": "flag-icon-us",
    "region": "US",
    "useFlag": true,
    "storeSource": "US",
    "countryCode": "US",
    "hreflang": "en-US",
    "countrySeoName": "US"
  },
]