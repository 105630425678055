export default {
  'wlgore.com': 'WLGore Associates',
  'sitkagear.com': 'SitkaGear Associates',
  'qbp.com': 'QBP Employees',
  'boatechnology.com': 'BOA Technology',
  'huckberry.com': 'Huckberry',
  'saris.com': 'Saris',
  'tailwindnutrition.com': 'Tailwindnutrition',
  'helenoftroy.com': 'Helenoftroy',
  'pivotcycles.com': 'Pivotcycles',
  'smithoptics.com': 'Smithoptics',
  'skratchlabs.com': 'Skratchlabs',
  'evocsports.com': 'EVOCsports',
  'explorethousand.com': 'Explorethousand',
  'drinkbivo.com': 'Drinkbivo',
  'garmin.com': 'Garmin',
  'yakima.com': 'Yakima',
  'otterproducts.com': 'Otterproducts',
  'built.com': 'Built',
  'kodiakcakes.com': 'Kodiakcakes',
  'goalzero.com': 'Goalzero',
  'gozney.com': 'Gozney',
  'logitech,com': 'Logitech',
  'stio.com': 'Stio',
  'caglobal.com': 'CAGlobal',
  'backbone.media': 'Backbone',
  'mtallo.33mail.com': 'Yakima', // start of testing accounts
  'manthan17.33mail.com': 'Huckberry',
  'manthan18.33mail.com': 'Garmin',
  'manthan19.33mail.com': 'EVOCsports',
}
