import axios from 'axios'
import Cookies from 'js-cookie'
import { displayPrice } from '@/lib/product-helper'
const { v4: uuidv4 } = require('uuid')
import { deleteToCart } from '@/redux/actions/cartAction'
import { updateCartModal,updateLoginModal } from '@/redux/actions/uiAction'
import { addToWishlist } from '@/redux/actions/userAction'
import { updateToCart } from '@/redux/actions/cartAction'
import * as types from '@/redux/actionTypes'
import { getRegion } from '@/lib/region-helper'
import { quantityChangeGTM } from '@/lib/gtm'

export const getCartData = async () => {
  try {
    const cartData = new Array()
    let cartId = getCartId()
    if (cartId == undefined) {
      return null
    } else {
      const response = await axios.post('/api/bigcommerce/carts/get', { cart_id: cartId })
      const cData = response.data.data
      const data = response.data.data.line_items.physical_items
      cartData.push({
        cartData: {
          base_amount: cData.base_amount,
          cart_amount: cData.cart_amount,
          coupons: cData.coupons,
          currency: cData.currency.code,
        },
      })
      data.map(async (item) => {
        let name = item.name
        let image = item.image_url
        let url = item.url
        let variant_id = item.variant_id
        let product_id = item.product_id
        let price = item.sale_price
        let qty = item.quantity

        const variantData = await axios.post('api/bigcommerce/products/variants', {
          pId: product_id,
          vId: variant_id,
        })
        const vData = variantData.data.data.option_values
        const optArr = new Array()
        vData.map((varnt) => {
          optArr.push(varnt.label)
        })
        cartData.push({
          [product_id]: {
            name: name,
            image: image,
            url: url,
            price: price,
            qty: qty,
            options: optArr,
          },
        })
      })
    }

    return cartData
  } catch (error) {
    throw error
  }
}

export const getCartId = () => {
  const cartId = Cookies.get('bc_cartId')
  return cartId
}

const createCookie = (name, value, days) => {
  if (days) {
    var date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    var expires = '; expires=' + date.toGMTString()
  } else {
    var expires = ''
  }
  document.cookie = name + '=' + value + expires + '; path=/'
}

const readCookie = (name) => {
  var nameEQ = name + '='
  var ca = document.cookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1, c.length)
    }
    if (c.indexOf(nameEQ) == 0) {
      return c.substring(nameEQ.length, c.length)
    }
  }
  return null
}

export const eraseCookie = (name) => {
  createCookie(name, '', -1)
}

// add item to cart using custom list price
export const addCartItem = async (
  product,
  variantId,
  currentUser,
  customerGroup,
  price,
  setRerender
) => {
  const listPrice = displayPrice(currentUser, product, customerGroup, price, false)

  const url = `${process.env.NEXT_PUBLIC_COMMERCE_URL}/cart`

  const item = {
    productId: product.entityId,
    variantId: variantId,
    customerId: currentUser != null ? currentUser['custom:customer_id'] : null,
    //listPrice: listPrice
  }

  if (price.salePrice != null) {
    item.listPrice = listPrice
  }

  const { data } = await axios.post(
    url,
    {
      item,
    },
    { withCredentials: true }
  )

  //needed for parent commerce provider to rerender
  setRerender(Math.random())
}

export const cartUpdateUserId = async (customerId = null, customerGroupId) => {
  const cartId = getCartId()

  const url = `${process.env.NEXT_PUBLIC_COMMERCE_URL}/cart/customerId`

  if (typeof cartId !== 'undefined') {
    const { data } = await axios.put(url, {
      cartId,
      customerId: customerId == null ? 0 : customerId,
    })

    if (customerId == null) {
      await removeProDiscount()
    } else {
      await applyProDiscount(customerId, customerGroupId)
    }
  }
}

export const removeProDiscount = async () => {
  const cartId = getCartId()
  let country = getRegion(process.env.NEXT_PUBLIC_SITE_REGION)
  const url = `${process.env.NEXT_PUBLIC_COMMERCE_URL}/cart/removeProDiscount`

  if (typeof cartId !== 'undefined') {
    const { data, Header } = await axios.put(url, { cartId })
    const cartCookie = Header['Set-Cookie']
    document.cookie = cartCookie
    const localizedCartCookie = cartCookie.replace(
      'bc_cartId',
      `bc_cartId_${country.storeSource.toLowerCase()}`
    )
    document.cookie = localizedCartCookie
  }
}

export const applyProDiscount = async (customerId, customerGroupId) => {
  const cartId = getCartId()
  let country = getRegion(process.env.NEXT_PUBLIC_SITE_REGION)
  const url = `${process.env.NEXT_PUBLIC_COMMERCE_URL}/cart/applyProDiscount`

  if (typeof cartId !== 'undefined') {
    const { data, Header } = await axios.put(url, {
      cartId,
      customerId,
      customerGroupId,
    })
    const cartCookie = Header['Set-Cookie']
    document.cookie = cartCookie
    const localizedCartCookie = cartCookie.replace(
      'bc_cartId',
      `bc_cartId_${country.storeSource.toLowerCase()}`
    )
    document.cookie = localizedCartCookie
  }
}

export const deleteCartItem = async (item, dispatch) =>  {

  dispatch({ type: types.UPDATE_CART_LOADING_REQUEST, payload: true })

  try {
    await dispatch(deleteToCart(item))
  } catch (err) {
    // Handle Error Here
    console.error('deleteCartItem err', err)
    let em = []
    if (err.response && err.response.data) {
      em.push(err.response.data.message)
    } else {
      em.push(err.message)
    }
    // addToast(<ErrorItemsNode errors={em} />, { appearance: 'error' })
  } finally {
    dispatch({ type: types.UPDATE_CART_LOADING_REQUEST, payload: false })
  }
}

export const addToWishList = async (item, dispatch, isAuthenticated, cartItems) => {

  dispatch({ type: types.UPDATE_CART_LOADING_REQUEST, payload: true })

  try {

    if (!isAuthenticated) {
      await dispatch(updateLoginModal(true))
      await dispatch(updateCartModal(false))
    } else {

      let wishObj = {
        items: [
          {
            product_id: item.product_id,
            variant_id: item.variant_id,
          },
        ]
      }

      await dispatch(addToWishlist(wishObj))

      let updatedAllCartItems = cartItems.map(cartItem => {

        if (item.id == cartItem.id) {
          return {
            ...item,
            added_to_wishlist: true
          }
        }

        return cartItem
      })

      await dispatch(deleteToCart(item))

      await dispatch(updateAllCartItems(updatedAllCartItems))
    }
  } catch (err) {

    let em = []
    if (err.response && err.response.data) {
      em.push(err.response.data.message)
    } else {
      em.push(err.message)
    }
    // addToast(<ErrorItemsNode errors={em} />, { appearance: 'error' })
  } finally {
    dispatch({ type: types.UPDATE_CART_LOADING_REQUEST, payload: false })
  }

}

export const updateQuantity = async (item, dispatch, value, quantityForGtm) => {
  dispatch({ type: types.UPDATE_CART_LOADING_REQUEST, payload: true })

  let val = parseInt(value || 1)
  let qty = val == 0 ? 1 : val

  try {
    await dispatch(updateToCart({
      itemId: item.id,
      item: { productId: item.product_id, variantId: item.variant_id, quantity: qty }
    }))
  } catch (err) {
    // Handle Error Here
    console.log('err', err.message)
    let em = []
    if (err.response && err.response.data) {
      em.push(err.response.data.message)
    } else {
      em.push(err.message)
    }
    // addToast(<ErrorItemsNode errors={em} />, { appearance: 'error' })
  } finally {

    //GTM
    if (quantityForGtm) {
      const { product } = item;
      quantityChangeGTM(product, qty, quantityForGtm)
    }
    //

    dispatch({ type: types.UPDATE_CART_LOADING_REQUEST, payload: false })
  }
}

export const getSize = (item) => {
  let itemSize = ''
  if (item.options && item.options.length) {
    itemSize = item.options.find((op) => op.name == 'Size').value
  }
  return itemSize
}

export const getColor = (item) => {
  let itemColor = ''
  if (item.options && item.options.length) {
    itemColor = item.options.find((op) => op.name == 'Color').value
  }
  return itemColor
}

export const getCartCurrencySymbol = () => {
  let checkoutLocale = localStorage.getItem('user_checkout_locale')

  if (checkoutLocale) {

    let cartLocaleData = JSON.parse(checkoutLocale)
    return cartLocaleData.currencySymbol
  } else {
    let country = getRegion(process.env.NEXT_PUBLIC_SITE_REGION)

    if (country) {
      return country.currencySymbol
    }

    return '$'
  }
}

export const switchRegionCart = (currentRegion, nextRegion) => {
  //cartCookie.replace('bc_cartId',`bc_cartId_${(country.region).toLowerCase()}`)

  if (currentRegion != nextRegion) {
    let cartCookieName = 'bc_cartId'
    let cartCookie = Cookies.get(cartCookieName)

    if (cartCookie) { // check if region has cart
      let localizedCartCookieName = `${cartCookieName}_${nextRegion}`
      let localizedCartCookie = Cookies.get(localizedCartCookieName)

      if (localizedCartCookie) {
      // if localized exist replace cartId with the localized cartId
        Cookies.set(cartCookieName, localizedCartCookie, { expires: 30})
      } else {
        // remove cart id if no localized cart id is stored
        Cookies.remove(cartCookieName, process.env.NEXT_PUBLIC_BASE_URL)
      }
    }

  }
}