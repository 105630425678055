import * as types from '@/redux/actionTypes'
import { jwtDecode } from "jwt-decode";

const initialState = {
  isAuthenticated: null,
  userInfo: {},
  membership: {
    isActive: false,
    bigCommCustomerGroupID: null,
    customerGroup: null,
    community: 'Experts',
    category: null,
    discount: null,
    expiry_date: null,
  },
  pingPkce: {
    code_challenge: '',
    code_verifier: '',
    state: '',
  },
  access_token: '',
  expires_in: '',
  expires_at: '',
  id_token: '',
  profile: {},
}

const ApiRecipe = (draft = initialState, action) => {
  switch (action.type) {
    case types.AUTH_TOKEN_SUCCESS:
      const decoded = jwtDecode(action.payload.access_token)
      const expires_at = decoded['exp']
      return {
        ...draft,
        isAuthenticated: true,
        access_token: action.payload.access_token,
        expires_in: action.payload.expires_in,
        expires_at: expires_at,
        id_token: action.payload.id_token,
        userInfo: decoded,
      }
    case types.ADD_PKCE_SUCCESS:
      return {
        ...draft,
        pingPkce: action.payload,
      }
    case types.LOAD_PROFILE_SUCCESS:
      return {
        ...draft,
        profile: action.payload,
      }
    case types.UPDATE_PROFILE_SUCCESS:
      return {
        ...draft,
        profile: action.payload,
      }
    case types.AUTH_SIGNOUT_SUCCESS:
      draft = initialState
      return {
        ...draft,
        isAuthenticated: false
      }
    case types.PROPROGRAM_CODE_VERIFY_SUCCESS:
      const { customerGroup, message, membership } = action.payload
      let verifyMembership = {}
      let verifyMembershipGroupId = customerGroup.id
      if (membership && customerGroup) {
        verifyMembership = membership
        verifyMembership.customerGroup = customerGroup
        verifyMembership.isActive = true
        verifyMembership.bigCommCustomerGroupID = membership.customer_group_id
        verifyMembershipGroupId = membership.customer_group_id
      } else {
        verifyMembership = initialState.membership
      }
      return {
        ...draft,
        membership: verifyMembership,
        userInfo: {
          ...draft.userInfo,
          bigCommCustomerGroupID: verifyMembershipGroupId,
        },
      }
    case types.PROPROGRAM_MEMBERSHIP_SUCCESS:
      const { customerGroup: currentCustomerGroup, membership: currentMembership } = action.payload
      let newMembership = {}
      let membershipGroupId = null
      if (currentMembership && currentCustomerGroup) {
        newMembership = currentMembership
        newMembership.customerGroup = currentCustomerGroup
        newMembership.isActive = true
        newMembership.bigCommCustomerGroupID = currentMembership.customer_group_id
        membershipGroupId = currentMembership.customer_group_id
      } else {
        newMembership = initialState.membership
      }
      return {
        ...draft,
        membership: newMembership,
        userInfo: {
          ...draft.userInfo,
          bigCommCustomerGroupID: membershipGroupId,
        },
      }
    default:
      return draft
  }
}

export const userReducer = ApiRecipe
