import * as types from '@/redux/actionTypes'
import produce, { original } from 'immer'

const initialState = {
  data: [],
  is_public: false,
  listId: 0,
  fetched: false,
}

const ApiRecipe = (draft = initialState, action) => {
  switch (action.type) {
    case types.LOAD_WISHLIST_SUCCESS:
      return {
        ...draft,
        ...action.payload,
      }
    case types.ADD_WISHLIST_SUCCESS:
      return {
        ...draft,
        data: draft.data.concat(action.payload),
      }
    case types.UPDATE_WISHLIST_SUCCESS:
      draft.is_public = action.payload.is_public
      break
    case types.REMOVE_WISHLIST_SUCCESS:
      const draftData = original(draft.data)
      const index = draftData.findIndex(todo =>  {
        return todo.id === action.payload.itemId
      })
      console.log('index', index)
      if (index !== -1) draft.data.splice(index, 1)
      break
    default:
      return draft
  }
}

export const wishlistReducer = produce(ApiRecipe)
