const pill = () =>{
  return ({ addComponents, config}) => {
    addComponents([
      {
        '.pill': {
          borderRadius: '4px',
          fontSize: '12px',
          height: '22px',
          lineHeight: '22px',
          padding: '0 10px'
        }
      }
    ])
  }
}

module.exports = pill;