import { combineReducers } from 'redux'
import { uiReducer } from './uiReducer'
import { userReducer } from './userReducer'
import { wishlistReducer } from './wishlistReducer'
import { cartReducer } from './cartReducer'
import { productReducer } from './productReducer'
import { reviewReducer } from './reviewReducer'

const rootReducer = combineReducers({
  uiReducer: uiReducer,
  userReducer: userReducer,
  wishlistReducer: wishlistReducer,
  cartReducer: cartReducer,
  productReducer: productReducer,
  reviewReducer: reviewReducer
})

export default rootReducer