import { createStore, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import { composeWithDevTools } from 'redux-devtools-extension'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import rootReducer from '../reducers'

const devMode = process.env.NODE_ENV !== 'production'
const isClient = typeof window !== 'undefined'
const initialState = {}

const bindMiddleware = (middleware) => {
  if (devMode) {
    return composeWithDevTools(applyMiddleware(...middleware, logger))
  }
  return applyMiddleware(...middleware)
}

let store = null
let persistor = null
if (!isClient) {
  //If it's on server side, create a store
  store = createStore(rootReducer, initialState, bindMiddleware([thunk]))
} else {
  //If it's on client side, create a store which will persist
  const persistConfig = {
    key: 'gore-na',
    whitelist: ['userReducer'], // only counter will be persisted, add other reducers if needed
    storage, // if needed, use a safer storage
  }

  const persistedReducer = persistReducer(persistConfig, rootReducer) // Create a new reducer with our existing reducer
  store = createStore(persistedReducer, initialState, bindMiddleware([thunk])) // Creating the store again
  persistor = persistStore(store) // This creates a persistor object & push that persisted object to .__persistor, so that we can avail the persistability feature
}

export { store, persistor }
