import * as types from '@/redux/actionTypes'
import produce from 'immer'

const initialState = {
  reviews: {
    list: [],
    totalReview: 0,
    averageScore: 0,
    pagination: {},
    productData: null,
    bottomLine: {},
    averageFit: 0,
    averageQuality: 0
  },
  isLoading: false
}

const ReviewRecipe = (draft = initialState, action) => {
  switch (action.type) {
    case types.LOAD_REVIEWS_REQUEST:
      const { reviews } = action.payload
      //console.log('currentVariant', currentVariant)
      return { ...draft, reviews, isLoading: true }
    case types.LOAD_REVIEWS_BOTTOMLINE_REQUEST:
      return {
        ...draft,
        reviews: {
          ...draft.reviews,
          totalReview: action.payload.totalReview ? action.payload.totalReview : 0,
          averageScore: action.payload.averageScore ? action.payload.averageScore : 0,
          averageFit: action.payload.averageFit ? action.payload.averageFit : 0,
          averageFitUnrounded: action.payload.averageFitUnrounded ? action.payload.averageFitUnrounded : 0,
          averageQuality: action.payload.averageQuality ? action.payload.averageQuality : 0
        }
      }
    default:
      return draft
  }
}

export const reviewReducer = produce(ReviewRecipe)
