const input = () =>{
  return ({ addComponents, config}) => {
    addComponents([
      {
        '.form-input': {
          outline: 'none',
          padding: '13px 20px'
        }
      }
    ])
  }
}

module.exports = input;