import regions from '@/config/regions'
import Cookies from 'js-cookie'
import { trans } from '@/lib/locale-helper'

export const getRegion = (domain) => {
  let region = regions.default.find((region) => region.domain == domain)

  if (region) {
    return region
  }

  return regions.default.find((region) => region.domain == 'en-us')
}

export const getAllRegions = () => {
  return regions.default
}

export const setCartLocale = (data) => {
  let country = getRegion(process.env.NEXT_PUBLIC_SITE_REGION)
  let domain = country.domain == 'en-us' ? 'us/en-us' : country.domain

  localStorage.setItem(
    'user_checkout_locale',
    JSON.stringify({
      ...data,
      checkoutUrl: `/${domain}/checkout-cart`,
    })
  )

  localStorage.setItem('c2BSet', data?.countryCode)
  Cookies.set('c2BSet', data?.countryCode, { domain: '.gorewear.com', secure: false })

  console.log('set the cart locale', data)
}

export const getCartLocale = (domain) => {
  let cartLocale = localStorage.getItem('user_checkout_locale')

  return cartLocale ? JSON.parse(cartLocale) : getRegion(domain)
}

export const setSiteLocale = (data) => {
  localStorage.setItem('c2BSet', data?.countryCode)
  localStorage.setItem('bclang', data?.domain)
  localStorage.setItem('country', data?.countryName)
  Cookies.set('bclang', data?.domain, { domain: '.gorewear.com', secure: false })
  Cookies.set('c2BSet', data?.countryCode, { domain: '.gorewear.com', secure: false })
}

export const updateSiteLocale = (domain) => {
  let country = getRegion(domain)

  let cartLocale = getCartLocale(domain)

  // ensure that cart locale matches its equivalent store.
  if (country.storeSource != cartLocale.storeSource) {
    setCartLocale(country)
  }

  let bcLang = localStorage.getItem('bclang')

  if (bcLang) {
    if (bcLang != domain) {
      setSiteLocale(country)
    }
  } else {
    setSiteLocale(country)
  }
}

export const sendPixelData = () => {
  let pixelFrame = document.getElementById('iframe-pixel')

  if (pixelFrame) {
    pixelFrame.contentWindow.postMessage(
      {
        action: 'localVars',
        bclang: window.localStorage.getItem('bclang'),
        user_checkout_locale: window.localStorage.getItem('user_checkout_locale'),
        c2BSet: window.localStorage.getItem('c2BSet'),
        country: window.localStorage.getItem('country'),
        cartItems: window.localStorage.getItem('cartItems'),
      },
      '*'
    )
  }
}

export const removeBlacklistedCountries = (countryList) => {
  //return countryList

  if (process.env.NEXT_PUBLIC_BLACKLISTED_COUNTRIES) {
    let blacklistedCountries = process.env.NEXT_PUBLIC_BLACKLISTED_COUNTRIES.split(',')

    if (blacklistedCountries.length > 0) {
      console.log('blacklistedCountries=============>', blacklistedCountries)

      return countryList.filter((country) => {
        let key = country.prefix ? 'prefix' : 'domain'

        return !blacklistedCountries.includes(country[key])
      })
    }
  }

  return countryList
}

export const getCurrentRegion = () => {
  let domain = process.env.NEXT_PUBLIC_SITE_REGION
  let region = regions.default.find((region) => region.domain == domain)

  if (region) {
    return region
  }

  return regions.default.find((region) => region.domain == 'en-us')
}

export const getRegionNumber = () => {
  let region = getCurrentRegion()
  let number = trans('1.800.551.2324', '1.800.551.2324')
  let numberPartials = number.split('|')

  if (numberPartials[1]) {
    if (region.domain == 'en-uk') {
      return numberPartials[0]
    }

    return numberPartials[1]
  }

  return numberPartials[0]
}

export const getCountryByName = (countryName) => {
  return regions.default.find((region) => region.countryName == countryName)
}

export const getCurrencySymbol = (currencyCode) => {
  if (currencyCode) {
    const region = regions.default.find((region) => {
      return region.isoCode.toLowerCase() === currencyCode.toLowerCase()
    })

    if (region) {
      return region.currencySymbol
    }

    return '$'
  }
}

export const getRegionDateFormat = () => {
  const region = getCurrentRegion()

  return region.storeSource == 'EU' ? 'dd/MM/yy' : 'MM/dd/yy'
}

export const selectShippingRegions = (item) => {

  let currentDomain = process.env.NEXT_PUBLIC_SITE_REGION
  let multiDomainSites = [
    'ch-it',
    'ch-fr',
    'ch-de',
    'be-en',
    'be-fr'
  ]

  if (multiDomainSites?.includes(currentDomain) && multiDomainSites?.includes(item?.domain)) {

    let currentRegion = getCurrentRegion()

    if (currentRegion?.countryCode == item?.countryCode) {
      return currentRegion
    }
  }

  return item
}
