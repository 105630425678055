export const isEmpty = (value) => {
  return [Object, Array].includes((value || {}).constructor) && !Object.entries((value || {})).length;
}

export const capitalize = (str) => {
  if (!str || typeof str !== 'string') {
    return '';
  }
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export const isBoolean = (value) => {
  return typeof value === 'boolean';
}

export const debounce = (func, wait) => {
  let timeout;
  return function(...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

export const groupBy = (array, func) => {
  return array.reduce((result, item) => {
    const key = func(item);
    if (!result[key]) {
      result[key] = [];
    }
    result[key].push(item);
    return result;
  }, {});
}

export const cloneDeep = (obj) => {
  let newObj = JSON.parse(JSON.stringify(obj));
  if (newObj === null || typeof(newObj) !== 'object' || 'isActiveClone' in newObj) {
    return newObj;
  }

  let temp = newObj instanceof Date ? new Date(newObj) : newObj instanceof RegExp ? new RegExp(newObj) : newObj.constructor ? new newObj.constructor() : Object.create(null);

  for (let key in newObj) {
    if (Object.prototype.hasOwnProperty.call(newObj, key)) {
      newObj['isActiveClone'] = null;
      temp[key] = cloneDeep(newObj[key]);
      delete newObj['isActiveClone'];
    }
  }

  return temp;
}

export const chunk = (array, size) => {
  if (!Array.isArray(array)) {
    throw new TypeError('Input should be an Array');
  }

  if (typeof size !== 'number') {
    throw new TypeError('Size should be a Number');
  }

  let result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
}