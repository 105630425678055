import * as types from '@/redux/actionTypes'
import produce from 'immer'

const initialState = {
  product: {
    currentPrice: null,
    currentVariant: null,
    isOutOfStock: null,
  },
  bgOverlay: false,
}

const ApiRecipe = (draft = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_PRODUCT_REQUEST:
      return {
        ...draft,
        product: action.payload,
      }
    case types.UPDATE_BG_OVERLAY:
      return {
        ...draft,
        bgOverlay: action.payload,
      }
    case types.PRODUCT_OPENED:
      return {
        ...draft,
        openProductId: action.payload.openProductId,
      }
    default:
      return draft
  }
}

export const productReducer = produce(ApiRecipe)
