// Authentication
export const ADD_PKCE_REQUEST = 'ADD_PKCE_REQUEST'
export const ADD_PKCE_SUCCESS = 'ADD_PKCE_SUCCESS'
export const ADD_PKCE_FAILURE = 'ADD_PKCE_FAILURE'

export const AUTH_TOKEN_REQUEST = 'AUTH_TOKEN_REQUEST'
export const AUTH_TOKEN_SUCCESS = 'AUTH_TOKEN_SUCCESS'
export const AUTH_TOKEN_FAILURE = 'AUTH_TOKEN_FAILURE'

export const AUTH_SIGNOUT_REQUEST = 'AUTH_SIGNOUT_REQUEST'
export const AUTH_SIGNOUT_SUCCESS = 'AUTH_SIGNOUT_SUCCESS'
export const AUTH_SIGNOUT_FAILURE = 'AUTH_SIGNOUT_FAILURE'

export const LOAD_PROFILE_REQUEST = 'LOAD_PROFILE_REQUEST'
export const LOAD_PROFILE_SUCCESS = 'LOAD_PROFILE_SUCCESS'
export const LOAD_PROFILE_FAILURE = 'LOAD_PROFILE_FAILURE'

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST'
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE'

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST'
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE'

export const LOAD_WISHLIST_REQUEST = 'LOAD_WISHLIST_REQUEST'
export const LOAD_WISHLIST_SUCCESS = 'LOAD_WISHLIST_SUCCESS'
export const LOAD_WISHLIST_FAILURE = 'LOAD_WISHLIST_FAILURE'

export const ADD_WISHLIST_REQUEST = 'ADD_WISHLIST_REQUEST'
export const ADD_WISHLIST_SUCCESS = 'ADD_WISHLIST_SUCCESS'
export const ADD_WISHLIST_FAILURE = 'ADD_WISHLIST_FAILURE'

export const UPDATE_WISHLIST_REQUEST = 'UPDATE_WISHLIST_REQUEST'
export const UPDATE_WISHLIST_SUCCESS = 'UPDATE_WISHLIST_SUCCESS'
export const UPDATE_WISHLIST_FAILURE = 'UPDATE_WISHLIST_FAILURE'

export const REMOVE_WISHLIST_REQUEST = 'REMOVE_WISHLIST_REQUEST'
export const REMOVE_WISHLIST_SUCCESS = 'REMOVE_WISHLIST_SUCCESS'
export const REMOVE_WISHLIST_FAILURE = 'REMOVE_WISHLIST_FAILURE'

export const PROPROGRAM_CODE_VERIFY_REQUEST = 'PROPROGRAM_CODE_VERIFY_REQUEST'
export const PROPROGRAM_CODE_VERIFY_SUCCESS = 'PROPROGRAM_CODE_VERIFY_SUCCESS'
export const PROPROGRAM_CODE_VERIFY_FAILURE = 'PROPROGRAM_CODE_VERIFY_FAILURE'

export const PROPROGRAM_MEMBERSHIP_REQUEST = 'PROPROGRAM_MEMBERSHIP_REQUEST'
export const PROPROGRAM_MEMBERSHIP_SUCCESS = 'PROPROGRAM_MEMBERSHIP_SUCCESS'
export const PROPROGRAM_MEMBERSHIP_FAILURE = 'PROPROGRAM_MEMBERSHIP_FAILURE'

// Cart Action
export const UPDATE_ITEM_QTY_REQUEST = 'UPDATE_ITEM_QTY_REQUEST'
export const UPDATE_ITEM_QTY_SUCCESS = 'UPDATE_ITEM_QTY_SUCCESS'
export const UPDATE_ITEM_QTY_FAILURE = 'UPDATE_ITEM_QTY_FAILURE'

export const LOAD_CART_REQUEST = 'LOAD_CART_REQUEST'
export const LOAD_CART_SUCCESS = 'LOAD_CART_SUCCESS'
export const LOAD_CART_FAILURE = 'LOAD_CART_FAILURE'

export const ADD_CART_REQUEST = 'ADD_CART_REQUEST'
export const ADD_CART_SUCCESS = 'ADD_CART_SUCCESS'
export const ADD_CART_FAILURE = 'ADD_CART_FAILURE'

export const UPDATE_CART_REQUEST = 'UPDATE_CART_REQUEST'
export const UPDATE_CART_SUCCESS = 'UPDATE_CART_SUCCESS'
export const UPDATE_CART_FAILURE = 'UPDATE_CART_FAILURE'

export const DELETE_CART_REQUEST = 'DELETE_CART_REQUEST'
export const DELETE_CART_SUCCESS = 'DELETE_CART_SUCCESS'
export const DELETE_CART_FAILURE = 'DELETE_CART_FAILURE'

export const APPLY_CART_PRO_REQUEST = 'APPLY_CART_PRO_REQUEST'
export const APPLY_CART_PRO_SUCCESS = 'APPLY_CART_PRO_SUCCESS'
export const APPLY_CART_PRO_FAILURE = 'APPLY_CART_PRO_FAILURE'

export const REMOVE_CART_PRO_REQUEST = 'REMOVE_CART_PRO_REQUEST'
export const REMOVE_CART_PRO_SUCCESS = 'REMOVE_CART_PRO_SUCCESS'
export const REMOVE_CART_PRO_FAILURE = 'REMOVE_CART_PRO_FAILURE'

export const UPDATE_ALL_CART_ITEMS_REQUEST = 'UPDATE_ALL_CART_ITEMS_REQUEST'
export const UPDATE_ALL_CART_ITEMS_SUCCESS = 'UPDATE_ALL_CART_ITEMS_SUCCESS'
export const UPDATE_ALL_CART_ITEMS_FAILURE = 'UPDATE_ALL_CART_ITEMS_FAILURE'

export const UPDATE_CART_LOADING_REQUEST = 'UPDATE_CART_LOADING_REQUEST'
export const UPDATE_CART_LOADING_SUCCESS = 'UPDATE_CART_LOADING_SUCCESS'
export const UPDATE_CART_LOADING_FAILURE = 'UPDATE_CART_LOADING_FAILURE'

export const UPDATE_CART_SHIPPING_LOCATION_REQUEST = 'UPDATE_CART_SHIPPING_LOCATION_REQUEST'
export const UPDATE_CART_SHIPPING_LOCATION_SUCCESS = 'UPDATE_CART_SHIPPING_LOCATION_SUCCESS'
export const UPDATE_CART_SHIPPING_LOCATION_FAILURE= 'UPDATE_CART_SHIPPING_LOCATION_FAILURE'

export const UPDATE_CART_CURRENCY_SYMBOL_REQUEST = 'UPDATE_CART_CURRENCY_SYMBOL_REQUEST'
export const UPDATE_CART_CURRENCY_SYMBOL_SUCCESS = 'UPDATE_CART_CURRENCY_SYMBOL_SUCCESS'
export const UPDATE_CART_CURRENCY_SYMBOL_FAILURE= 'UPDATE_CART_CURRENCY_SYMBOL_FAILURE'

export const CLEAR_CART_REMOVED_ITEMS_REQUEST = 'CLEAR_CART_REMOVED_ITEMS_REQUEST'
export const CLEAR_CART_REMOVED_ITEMS_SUCCESS = 'CLEAR_CART_REMOVED_ITEMS_SUCCESS'
export const CLEAR_CART_REMOVED_ITEMS_FAILURE= 'CLEAR_CART_REMOVED_ITEMS_FAILURE'


// Product Action
export const UPDATE_PRODUCT_REQUEST = 'UPDATE_PRODUCT_REQUEST'
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS'
export const UPDATE_PRODUCT_FAILURE = 'UPDATE_PRODUCT_FAILURE'
export const UPDATE_BG_OVERLAY = 'UPDATE_BG_OVERLAY'
export const PRODUCT_OPENED = 'PRODUCT_OPENED'

// UI Action
export const UPDATE_CART_MODAL_REQUEST = 'UPDATE_CART_MODAL_REQUEST'
export const UPDATE_CART_MODAL_SUCCESS = 'UPDATE_CART_MODAL_SUCCESS'
export const UPDATE_CART_MODAL_FAILURE = 'UPDATE_CART_MODAL_FAILURE'

export const UPDATE_LOGIN_MODAL_REQUEST = 'UPDATE_LOGIN_MODAL_REQUEST'
export const UPDATE_LOGIN_MODAL_SUCCESS = 'UPDATE_LOGIN_MODAL_SUCCESS'
export const UPDATE_LOGIN_MODAL_FAILURE = 'UPDATE_LOGIN_MODAL_FAILURE'

export const UPDATE_COUNTRY_MODAL_REQUEST = 'UPDATE_COUNTRY_MODAL_REQUEST'
export const UPDATE_COUNTRY_MODAL_SUCCESS = 'UPDATE_COUNTRY_MODAL_SUCCESS'
export const UPDATE_COUNTRY_MODAL_FAILURE = 'UPDATE_COUNTRY_MODAL_FAILURE'

export const UPDATE_BREAKPOINT_REQUEST = 'UPDATE_BREAKPOINT_REQUEST'
export const UPDATE_BREAKPOINT_SUCCESS = 'UPDATE_BREAKPOINT_SUCCESS'
export const UPDATE_BREAKPOINT_FAILURE = 'UPDATE_BREAKPOINT_FAILURE'

// Reviews
export const LOAD_REVIEWS_REQUEST = 'LOAD_REVIEWS_REQUEST'
export const LOAD_REVIEWS_SUCCESS = 'LOAD_REVIEWS_SUCCESS'
export const LOAD_REVIEWS_FAILURE = 'LOAD_REVIEWS_FAILURE'

export const LOAD_REVIEWS_BOTTOMLINE_REQUEST = 'LOAD_REVIEWS_BOTTOMLINE_REQUEST'
export const LOAD_REVIEWS_BOTTOMLINE_SUCCESS = 'LOAD_REVIEWS_BOTTOMLINE_SUCCESS'
export const LOAD_REVIEWS_BOTTOMLINE_FAILURE = 'LOAD_REVIEWS_BOTTOMLINE_FAILURE'
