import queryString from 'query-string'
import Router from 'next/router'
import { Notyf } from 'notyf'
import Cookies from 'js-cookie'
import { AuthClient } from '@/lib/Auth/auth-client'
import { getCountrySelectorData, sleep } from '@/lib/helpers'
import { getGroupNameInfo, allowGroupDiscount } from '@/lib/product-helper'

import { getCart, applyProDiscount, removeProDiscount, updateCartShippingLocation, setCartRegion } from '@/redux/actions/cartAction'
import { getRegion, setCartLocale, getCartLocale } from '@/lib/region-helper'
import * as types from '@/redux/actionTypes'

const waitForLocalStorageUpdate = async (dispatch, resPayload, attemptsLeft = 5) => {
  const checkTokenIsValid = getLocalstorageItem(3)
  console.log('waitForLocalStorageUpdate attemptsLeft', attemptsLeft)
  console.log('waitForLocalStorageUpdate checkTokenIsValid', checkTokenIsValid)
  if (checkTokenIsValid) {
    return checkTokenIsValid
  } else if (attemptsLeft) {
    dispatch({ type: types.AUTH_TOKEN_SUCCESS, payload: resPayload })
    console.log('waitForLocalStorageUpdate dispatch is fired....')
    return new Promise((res) => {
      setTimeout(() => {
        waitForLocalStorageUpdate(dispatch, resPayload, attemptsLeft - 1).then((resp) => res(resp))
      }, 1000)
    })
  } else {
    throw new Error('Could not get value from localStorage')
  }
}

export const getAccessToken = (code, code_verifier, region) => async (dispatch) => {
  const exchangeOptions = queryString.stringify({
    client_id: process.env.NEXT_PUBLIC_PING_LOGIN_CLIENT,
    grant_type: 'authorization_code',
    code: code,
    redirect_uri: `${process.env.NEXT_PUBLIC_BASE_URL}/${region}/auth-callback`,
    code_verifier: code_verifier,
  })
  dispatch({ type: types.AUTH_TOKEN_REQUEST })
  try {
    const res = await AuthClient.post(
      `${process.env.NEXT_PUBLIC_PING_SERVER_URL}/as/token.oauth2`,
      exchangeOptions,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    )
    console.log('OAUTH 2 RES', res)
    // localStorage.setItem('gore:token', JSON.stringify(res))
    dispatch({ type: types.AUTH_TOKEN_SUCCESS, payload: res })
    await sleep(1000)
    await waitForLocalStorageUpdate(dispatch, res, 5)
    await dispatch(onLogin())
  } catch (err) {
    dispatch({ type: types.AUTH_TOKEN_FAILURE, payload: err })
    console.log('err', err)
    throw err
    //throw new Error('list not found')
  }
}

export const addPkceData = (pkceObj) => async (dispatch) => {
  dispatch({ type: types.ADD_PKCE_REQUEST })
  try {
    dispatch({ type: types.ADD_PKCE_SUCCESS, payload: pkceObj })
  } catch (err) {
    dispatch({ type: types.ADD_PKCE_FAILURE, payload: err })
    console.log('addPkceData err:', err)

    //throw new Error('list not found')
  }
}

export const onLogin = () => async (dispatch, getState) => {
  await sleep(1000)
  console.debug('onLogin - onLogin', getState())
  await dispatch(getProfile())
  await dispatch(checkMembershipApi())
  await dispatch(loadCart())
  await dispatch(fetchWishlist())
}

export const getLocalstorageItem = (condition = 1) => {
  const usersInfo = JSON.parse(localStorage.getItem('persist:gore-na'))
  console.debug('getLocalstorageItem usersInfo', usersInfo)
  if (usersInfo && usersInfo['userReducer']) {
    const userData = JSON.parse(usersInfo['userReducer'])
    console.debug('getLocalstorageItem userData', userData, "this is the user data")
    switch (condition) {
      case 1: // get customer id
        return userData['userInfo']['bigCommCustomerID'] || 0
      case 2: // get customer group id
        return userData['userInfo']['bigCommCustomerGroupID'] || 0

      case 3: // token exists and valid
        return (
          userData &&
          userData.access_token &&
          userData.expires_at &&
          +new Date() < userData.expires_at * 1000
        )
      case 4: // get token expires time
        return userData['expires_at'] || 0

      case 5: // check token is present in localStorage
        return userData.access_token || 0
      case 6: // get user info
        return userData['userInfo']
      default:
        // return customer id
        return userData['userInfo']['bigCommCustomerID'] || 0
    }
  }
  return 0
}

export const isCorrectRegionForLoggedInUser = () => {
  console.log('getCountrySelectorData', getCountrySelectorData());
  let countryData = getCountrySelectorData();
  let region = countryData && countryData.region == 'US' ? 'us' : 'eu';
  let persistData = {};

  try {
    persistData = JSON.parse(JSON.parse(localStorage.getItem('persist:gore-na')).userReducer);
    console.log('persistData', persistData, persistData.userInfo.channel); // gorewearus
  } catch (e) {
    console.log('err', e);
  }

  const userCountry = persistData.userInfo.channel == 'gorewearus' ? 'us' : persistData.userInfo.channel == 'gorewearus' ? 'eu' : null;

  return !!userCountry && (userCountry == region);
}

export const loadCart = () => async (dispatch, getState) => {
  try {
    const cartCookies = Cookies.get('bc_cartId')
    const userGroup = getLocalstorageItem(2)
    const { userReducer } = getState()
    const groupNameInfo = userReducer && userReducer.membership && userReducer.membership.group ? getGroupNameInfo(userReducer.membership.group) : null

    let selectedCountry = localStorage?.getItem('user_checkout_locale')
    if (selectedCountry) {
      selectedCountry = JSON.parse(selectedCountry)
    }
    console.debug('loadCart - cartCookies', cartCookies)
    console.debug('loadCart - userGroup', userGroup)
    if (cartCookies && userGroup && allowGroupDiscount(groupNameInfo)) {
      await dispatch(applyProDiscount())
      await sleep(100)
    } else if (cartCookies) {
      await dispatch(getCart())
    }
    /* const cartCookies2 = Cookies.get('bc_cartId')
    if (cartCookies2) {
      await dispatch(getCart())
    } */
  } catch (err) {
    // Handle Error Here
    console.log('loadCart err:', err)
  } finally {
    dispatch({ type: types.UPDATE_CART_LOADING_REQUEST, payload: false })
  }
}

export const onLocalLogin = () => async (dispatch) => {
  const checkTokenIsValid = getLocalstorageItem(3)
  // isCorrectRegionForLoggedInUser();
  console.debug('onLocalLogin checkTokenIsValid', !!checkTokenIsValid && isCorrectRegionForLoggedInUser())
  // await setCartRegion()

  if (checkTokenIsValid) {
    // authorize
    console.debug('onLocalLogin - authorize')
    const tokenExpiresAfterMin = getLocalstorageItem(4)
    console.debug('onLocalLogin - tokenExpiresAfterMin', tokenExpiresAfterMin)
    await dispatch(onLogin())
  } else {
    //unauth
    console.debug('onLocalLogin - unauth')
    await sleep(200)
    localStorage.removeItem('persist:gore-na');
    dispatch({ type: types.AUTH_SIGNOUT_SUCCESS, payload: {} })
    // window.location.reload()
    await sleep(100)
    await dispatch(loadCart())
    //Router.reload()
  }

  try {
    let country = getRegion(process.env.NEXT_PUBLIC_SITE_REGION)
    let cartLocale = localStorage.getItem('user_checkout_locale')

    if (!cartLocale) {

      setCartLocale(country)

      if (country.storeSource == 'EU') {
        await dispatch(updateCartShippingLocation(country))
      }
    }
  } catch (error) {}
}

export const getProfile = () => async (dispatch) => {
  dispatch({ type: types.LOAD_PROFILE_REQUEST })
  try {
    let countryData = getCountrySelectorData();
    let userInfo = getLocalstorageItem(6);
    let region = countryData && countryData.region == 'US' ? 'us' : 'eu';
    if (userInfo && ((userInfo?.channel == 'goreweareu' && region == 'us') || (userInfo?.channel == 'gorewearus' && region == 'eu'))) {
      dispatch({ type: types.AUTH_SIGNOUT_SUCCESS, payload: {} })
      return;
    }
    const { profile } = await AuthClient.get(`/profile`)
    dispatch({
      type: types.LOAD_PROFILE_SUCCESS,
      payload: profile,
    })
  } catch (err) {
    // Handle Error Here
    dispatch({ type: types.LOAD_PROFILE_FAILURE, payload: err })
    console.log('getProfile err', err)
    throw err
  }
}

export const updateProfile = (values) => async (dispatch) => {
  dispatch({ type: types.UPDATE_PROFILE_REQUEST })
  try {
    const { profile } = await AuthClient.post(`/update-profile`, values)
    dispatch({
      type: types.UPDATE_PROFILE_SUCCESS,
      payload: profile,
    })
  } catch (err) {
    // Handle Error Here
    dispatch({ type: types.UPDATE_PROFILE_FAILURE, payload: err })
    console.log('updateProfile err', err)
    throw err
  }
}

export const changePassword = (values) => async (dispatch) => {
  dispatch({ type: types.UPDATE_PASSWORD_REQUEST })
  try {
    const { profile } = await AuthClient.post(`/change-password`, values)
    dispatch({
      type: types.UPDATE_PASSWORD_SUCCESS,
      payload: profile,
    })
  } catch (err) {
    // Handle Error Here
    dispatch({ type: types.UPDATE_PASSWORD_FAILURE, payload: err })
    console.log('changePassword err', err)
    throw err
  }
}

export const userSignOut = () => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch({ type: types.AUTH_SIGNOUT_REQUEST })
    try {
      const cartLocale = getCartLocale()

      await dispatch(removeProDiscount(cartLocale.domain))
      dispatch({ type: types.AUTH_SIGNOUT_SUCCESS, payload: {} })
      resolve({})
    } catch (err) {
      dispatch({ type: types.AUTH_SIGNOUT_FAILURE })
      console.log('err', err)
      reject(err)
      //throw new Error('list not found')
    }
  })
}

export const onUnauthorizedToken = () => async (dispatch) => {
  dispatch({ type: types.AUTH_SIGNOUT_REQUEST })
  try {
    const notyf = new Notyf({
      duration: 3000,
      position: {
        x: 'right',
        y: 'top',
      },
    })
    // Display an error notification
    notyf.error('Unauthorized')
    dispatch({ type: types.AUTH_SIGNOUT_SUCCESS, payload: {} })
    setTimeout(() => {
      let countryData = getCountrySelectorData();
      window.location.replace('/' + countryData.prefix);
    }, 2500)
  } catch (err) {
    dispatch({ type: types.AUTH_SIGNOUT_FAILURE })
    console.log('err', err)
    throw err
    //throw new Error('list not found')
  }
}

export const fetchWishlist = () => async (dispatch) => {
  dispatch({ type: types.LOAD_WISHLIST_REQUEST })
  try {
    const { wishlist, is_public, listId } = await AuthClient.get(`/wishlists`)
    dispatch({
      type: types.LOAD_WISHLIST_SUCCESS,
      payload: { data: wishlist, is_public: is_public, listId: listId, fetched: true },
    })
  } catch (err) {
    // Handle Error Here
    dispatch({ type: types.LOAD_WISHLIST_FAILURE })
    console.log('err', err)
  }
}

export const addToWishlist = (wishObj) => async (dispatch) => {
  dispatch({ type: types.ADD_WISHLIST_REQUEST })
  try {
    const { wishlistAddItem } = await AuthClient.post(`/wishlists`, wishObj)
    dispatch({
      type: types.ADD_WISHLIST_SUCCESS,
      payload: wishlistAddItem,
    })
  } catch (err) {
    // Handle Error Here
    dispatch({ type: types.ADD_WISHLIST_FAILURE })
    console.log('err', err)
  }
}

export const updateToWishlist = (listId, wishObj) => async (dispatch) => {
  dispatch({ type: types.UPDATE_WISHLIST_REQUEST })
  try {
    const { data: wlistData } = await AuthClient.put(`/wishlists/${listId}`, wishObj)
    dispatch({
      type: types.UPDATE_WISHLIST_SUCCESS,
      payload: { is_public: wlistData.data.is_public },
    })
  } catch (err) {
    // Handle Error Here
    dispatch({ type: types.UPDATE_WISHLIST_FAILURE })
    console.log('err', err)
  }
}

export const removeToWishlist = (wishObj) => async (dispatch) => {
  dispatch({ type: types.REMOVE_WISHLIST_REQUEST })
  try {
    await AuthClient.delete(`/wishlists`, { data: wishObj })
    dispatch({
      type: types.REMOVE_WISHLIST_SUCCESS,
      payload: wishObj,
    })
  } catch (err) {
    // Handle Error Here
    dispatch({ type: types.REMOVE_WISHLIST_FAILURE })
    console.log('err', err)
  }
}

// Pro program
export const verifyCode = (authenticationCode) => async (dispatch) => {
  dispatch({ type: types.PROPROGRAM_CODE_VERIFY_REQUEST })
  try {
    const { customerGroup, message, membership } = await AuthClient.post(`/pro-program/verify`, {
      authenticationCode: authenticationCode,
    })
    dispatch({
      type: types.PROPROGRAM_CODE_VERIFY_SUCCESS,
      payload: { customerGroup, message, membership },
    })
    return { customerGroup, message, membership }
  } catch (err) {
    // Handle Error Here
    dispatch({ type: types.PROPROGRAM_CODE_VERIFY_FAILURE })
    console.log('err', err)
    throw err
  }
}

export const checkMembershipApi = () => async (dispatch) => {
  dispatch({ type: types.PROPROGRAM_MEMBERSHIP_REQUEST })
  try {
    const { membership, customerGroup, expired } = await AuthClient.get(`/pro-program/membership`)
    if (expired) {
      const notyf = new Notyf({
        duration: 3000,
        position: {
          x: 'right',
          y: 'top',
        },
      })
      notyf.error('Your pro program membership has expired')
    }
    dispatch({
      type: types.PROPROGRAM_MEMBERSHIP_SUCCESS,
      payload: { membership, customerGroup },
    })
    return membership
  } catch (err) {
    // Handle Error Here
    dispatch({ type: types.PROPROGRAM_MEMBERSHIP_FAILURE })
    console.log('err', err)
    throw err
  }
}

export const addToCustomerGroup = async (email, groupName) => {
  try {
    await AuthClient.post('/customer-group/customer', {
      groupName,
      email
    })
  } catch (err) {
    console.log('err', err)
  }
}
