import { useReducer, createContext, useMemo } from 'react';

export const ProductContext = createContext();

const ProductProvider = ({ children}) => {

  const initialState = {
    comparedProducts: [],
    compareBoxAction: null,
    addingCompareProduct: false,
    product: {
      currentPrice: null,
      currentVariant: null,
      isOutOfStock: null
    },
    productIsAdded: false
  };

  const reducer = (state, action) => {

    switch(action.type) {
      case 'ADD_PRODUCT_TO_COMPARE':
        return {
          ...state,
          comparedProducts: [
            ...state.comparedProducts,
            action.payload
          ],
          compareBoxAction: 'add'
        }
      case 'REMOVE_PRODUCT_TO_COMPARE':
        return {
          ...state,
          comparedProducts: state.comparedProducts.filter(item => action.payload.id !== item.id),
          compareBoxAction: 'remove'
        }
      case 'CLEAR_COMPARED_PRODUCTS':
        return {
          ...state,
          comparedProducts: [],
          compareBoxAction: null
        }
      case 'SET_COMPARED_PRODUCTS':
        return {
          ...state,
          comparedProducts: action.payload.comparedProducts,
          compareBoxAction: action.payload.compareBoxAction
        }
      case 'UPDATE_ADDING_COMPARE_PRODUCT': {
        return {
          ...state,
          addingCompareProduct: action.payload
        }
      }
      case 'UPDATE_PRODUCT_DATA': {
        return {
          ...state,
          product: action.payload
        }
      }
      default:
        throw new Error();
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const productState = useMemo(() => [state, dispatch],[state, dispatch]);

  return (
    <ProductContext.Provider value={productState}>
      { children }
    </ProductContext.Provider>
  )
}

export default ProductProvider;