import resolveConfig from 'tailwindcss/resolveConfig'
import { default as categoryNav } from 'public/categories-data'
import tailwindConfig from '../tailwind.config'
import validationMessages from '@/config/validation-messages.json'
import nextConfig from 'next/config'
import seoConfig from '@/config/seo'
import partnerGroups from '@/config/partner-groups'
import * as fs from 'fs'
import countriesList from '@/config/countries.json'
import { getRegion, getAllRegions, getCurrentRegion } from '@/lib/region-helper'
import slugify from 'slugify'
import { getLocalizedVariantPrices } from './product-helper'

const config = resolveConfig(tailwindConfig)
const { publicRuntimeConfig } = nextConfig()
const isStaging = process.env.NEXT_PUBLIC_BASE_URL == 'https://www.origin.gorewear.com'
/* Common helper functions  */

// checks if object is empty, replaces _.isEmpty
export const isEmptyObject = (obj) => !(obj && obj === Object(obj) && Object.keys(obj).length !== 0)

// removes px text from string. usecase tailwind screen config
export const removePx = (string) => {
  return string.replace('px', '')
}

// sets the slider width based on the number of elements. either 2 or 3 per page
export const setSliderSlideWidth = (sliderSelector, defaultSliderPerPage = 3) => {
  if (typeof window !== 'undefined') {
    const sliderTrack = document.querySelector(`.${sliderSelector} .splide__track`)

    const windowWidth = window.innerWidth

    const sliderPerPage =
      windowWidth > config.theme.screens.md.replace('px', '') ? defaultSliderPerPage : 2

    const gap = 20 //1.25rem

    const sliderWidth = sliderTrack.offsetWidth / sliderPerPage - gap / 2 - 3.3

    return sliderWidth
  }

  return 0
}

// adds overflow hidden on the body to prevent scrolling if needed
export const disableBodyScroll = () => {
  if (typeof window != 'undefined') {
    const body = document.querySelector('body')

    body.classList.add('overflow-hidden', 'fixed', 'inset-0')
  }
}

// enables body scroll by removing overflow-hidden
export const enableBodyScroll = () => {
  if (typeof window != 'undefined') {
    const body = document.querySelector('body')

    if (body.classList.contains('overflow-hidden')) {
      body.classList.remove('overflow-hidden', 'fixed', 'inset-0')
    }
  }
}

// Replacement for above two functions (disableBodyScroll, enableBodyScroll) to deal with scrollable navigation
// adds overflow hidden on the body to prevent scrolling if needed
export const disableBodyScrollNav = () => {
  if (typeof window != 'undefined') {
    const body = document.querySelector('body')

    body.classList.add('overflow-hidden', 'relative')
  }
}

// enables body scroll by removing overflow-hidden
export const enableBodyScrollNav = () => {
  if (typeof window != 'undefined') {
    const body = document.querySelector('body')

    if (body.classList.contains('overflow-hidden')) {
      body.classList.remove('overflow-hidden', 'relative')
    }
  }
}

// creates an overlay on the content area(header excluded)
export const showContentOverlay = () => {
  if (typeof window != 'undefined') {
    const contentWrap = document.querySelector('.content-wrap')

    if (!contentWrap.classList.contains('overlay')) {
      contentWrap.classList.add('overlay')
    }
  }
}

// hides the content overlay
export const hideContentOverlay = () => {
  if (typeof window != 'undefined') {
    const contentWrap = document.querySelector('.content-wrap')

    if (contentWrap.classList.contains('overlay')) {
      contentWrap.classList.remove('overlay')
    }
  }
}

// encodes data to uri component
export const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

// Capitalize string
export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toLowerCase() + s.slice(1)
}

// initialize slider
export const initSlider = (selector, options) => {
  if (typeof window !== 'undefined') {
    require('@splidejs/splide/dist/css/splide.min.css')
    const Splide = require('@splidejs/splide').default
    const Grid = require('@splidejs/splide-extension-grid').default
    const slider = new Splide(selector, options)
    slider.mount({ Grid })

    return slider
  }

  return {}
}

export const DisplayFormikState = (props) => {
  if (!props.isShow) {
    return null
  }
  return (
    <div style={{ margin: '1rem 0' }}>
      <h3 style={{ fontFamily: 'monospace' }} />
      <pre
        style={{
          background: '#f6f8fa',
          fontSize: '.65rem',
          padding: '.5rem',
        }}
      >
        <strong>props</strong> = {JSON.stringify(props, null, 2)}
      </pre>
    </div>
  )
}

// check if object is empty
export const isObjectEmpty = (object) => {
  return Object.keys(object).length === 0
}

export const isEmpty = (string) => {
  const trim = string?.trim()
  return !trim || trim?.length === 0
}

// show validation message
export const validationMessage = (rule, field = null) => {
  let message = validationMessages[rule]

  if (field != null) {
    message = message.replace(':field', field)
  }

  return message
}

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const priceVal = (calPriceVal, salePriceVal, regPriceVal, discount = null) => {
  if (salePriceVal != null) {
    if (discount != null) {
      return parseFloat(Number(regPriceVal).toFixed(2))
    }
    return parseFloat(Number(calPriceVal).toFixed(2))
  }
  return parseFloat(Number(regPriceVal).toFixed(2))
}

const findItemNested = (arr, itemId, nestingKey) =>
  arr.reduce((a, item) => {
    if (a) return a
    if (item.id === itemId) return item
    if (item[nestingKey]) return findItemNested(item[nestingKey], itemId, nestingKey)
  }, null)

export const getCategoriesNames = (idsArr = [], resultArr = false) => {
  let catArr = []
  idsArr.map((id) => {
    const res = categoryNav.data.find(item => item.id == id)
    if (res != null) {
      catArr.push(res.name)
    }
  })
  let unique = [...new Set(catArr)]
  if (resultArr) {
    return unique
  }
  return unique.join(' / ')
}

export const validURL = (string) => {
  let url

  try {
    url = new URL(string)
  } catch (_) {
    return false
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
}

export const addForwardSlash = (url) => {
  try {
    if (validURL(url)) {
      return removeTrailingSlash(url)
    }

    if (url.charAt(0) != '/') {
      return '/' + removeTrailingSlash(url)
    }

    return removeTrailingSlash(url)
  } catch (error) {
    console.log(error)
  }
}

//get user local value form url
export const getUserLocal = () => {
  if (typeof window !== 'undefined') {
    let isCountrySwitched = localStorage.getItem('user_checkout_locale')
    if (isCountrySwitched && Object.keys(JSON.parse(isCountrySwitched)).length > 0) {
      return JSON.parse(isCountrySwitched).domain
    }
    let pathname = window.location.pathname.split('/').filter((n) => n)
    let pagePath = ''
    if (pathname[0] == 'us') {
      pagePath = pathname[1]
    } else {
      pagePath = pathname[0]
    }
    return pagePath
  }
  return 'en-us'
}

export const disableConsoleLogs = () => {
  var methods = ['log', 'debug', 'warn', 'info']
  for (var i = 0; i < methods.length; i++) {
    console[methods[i]] = function () {}
  }
}

// prepends www to the domain name
export const setBaseDomain = (url = '') => {
  if (String(url).match(/www/i)) {
    return url
  }

  let urlPartials = url.split('://')

  let newUrl = `${urlPartials[0]}://www.${urlPartials[1]}`

  return newUrl
}

export const setPageSeo = (page, seo, path = '') => {
  let seoDetails = {
    title: seo != null && seo?.title ? seo?.title : page.title,
    description: seo != null ? seo.description : seoConfig.description,
    canonical: `${setBaseDomain(process.env.NEXT_PUBLIC_BASE_URL)}${
      publicRuntimeConfig.basePath
    }${path}`,
    additionalMetaTags: [
      {
        name: 'keywords',
        content:
          seo != null
            ? isEmptyObject(seo.keywords)
              ? seoConfig.title
              : seo.keywords
            : seoConfig.title,
      },
    ],
    noindex: isStaging ? true : false,
    nofollow: isStaging ? true : false,
  }
  const region = getRegion(process.env.NEXT_PUBLIC_SITE_REGION)
  if (page.slug == 'homepage') {
    let newTitle = seoDetails.title
    if (newTitle.includes('GOREWEAR |')) {
      let titlePiece = newTitle.split('GOREWEAR |')[1]
      titlePiece = `GOREWEAR ${region.countrySeoName} | ${titlePiece}`.replace(/ {2,}/g, ' ')
      seoDetails.title = titlePiece
    }
  }
  return seoDetails
}

export const isAutoApprovedDomain = (email) => {
  if (
    process.env.NEXT_PUBLIC_AUTO_TAG_TEST_GROUP &&
    process.env.NEXT_PUBLIC_BASE_URL != 'https://www.gorewear.com'
  ) {
    // used in testing auto tagging. Please don't add this to prod instance
    return true
  }

  const associateDomains = Object.keys(partnerGroups)
  const domain = email.substring(email.lastIndexOf('@') + 1)

  return associateDomains.includes(domain)
}

export const getPartnerGroup = (email) => {
  if (
    process.env.NEXT_PUBLIC_AUTO_TAG_TEST_GROUP &&
    process.env.NEXT_PUBLIC_BASE_URL != 'https://www.gorewear.com'
  ) {
    // used in testing auto tagging. Please don't add this to prod instance
    return process.env.NEXT_PUBLIC_AUTO_TAG_TEST_GROUP
  }

  const domain = email.substring(email.lastIndexOf('@') + 1)

  if (partnerGroups[domain]) {
    return partnerGroups[domain]
  }

  return null
}

export const restructureMenus = (menusCollection) => {
  const menus = menusCollection[0].fields

  const firstColumn = menus ? formatMenusItem(menus.firstColumn) : {}
  const secondColumn = menus ? formatMenusItem(menus.secondColumn) : {}
  const thirdColumn = menus ? formatMenusItem(menus.thirdColumn) : {}
  const fourthColumn = menus ? formatMenusItem(menus.fourthColumn) : {}
  const fifthColumn = menus ? formatMenusItem(menus.fifthColumn) : {}
  const sixthColumn = menus ? formatMenusItem(menus.sixthColumn) : {}

  return [firstColumn, secondColumn, thirdColumn, fourthColumn, fifthColumn, sixthColumn]
}

const formatMenusItem = (items) => {
  //console.log('mt-----', items)
  let menu = {}

  if (items && items.length == 1) {
    menu.content = 1
    menu.heading = items[0]?.fields?.title
    menu.headingDefault = items?.[0]?.fields?.enTitle || items?.[0]?.fields?.title
    menu.slug = items[0]?.fields?.slug
    menu.slugDefault = items?.[0]?.fields?.enSlug || items?.[0]?.fields?.slug
    menu.makeTitleLink = items[0]?.fields?.makeTitleLink
    menu.sectionImage = {
      url: items[0]?.fields?.menuImage ? items[0]?.fields?.menuImage?.fields?.file?.url : '',
      urlTablet: items[0]?.fields?.menuImageTablet
        ? items[0]?.fields?.menuImageTablet?.fields?.file?.url
        : '',
      urlMobile: items[0]?.fields?.menuImageMobile
        ? items[0]?.fields?.menuImageMobile?.fields?.file?.url
        : '',
    }
    menu.links = []
    menu.mobileOnly = items[0]?.fields?.mobileOnly || false
    items[0]?.fields?.menuItems.map((i) => {
      menu.links.push({
        title: i?.fields?.title,
        link: i?.fields?.url,
        titleDefault: i?.fields?.enTitle || i?.fields?.title,
        linkDefault: i?.fields?.enUrl || i?.fields?.url,
        mobileOnly: i?.fields?.mobileOnly || false
      })
    })
  }
  if (items && items.length == 2) {
    menu.content = 2
    menu.heading = items[0]?.fields?.title
    menu.headingDefault = items?.[0]?.fields?.enTitle || items?.[0]?.fields?.title
    menu.slug = items[0]?.fields?.slug
    menu.makeTitleLink = items[0]?.fields?.makeTitleLink
    menu.heading2 = items[1]?.fields?.title
    menu.heading2Default = items?.[1]?.fields?.enTitle || items?.[1]?.fields?.title
    menu.slug2 = items[1]?.fields?.slug
    menu.slug2Default = items?.[1]?.fields?.enSlug || items?.[1]?.fields?.slug
    menu.makeTitleLink2 = items[1]?.fields?.makeTitleLink
    menu.sectionImage = {
      url: items[0]?.fields?.menuImage ? items[0]?.fields?.menuImage?.fields?.file?.url : '',
      urlTablet: items[0]?.fields?.menuImageTablet
        ? items[0]?.fields?.menuImageTablet?.fields?.file?.url
        : '',
      urlMobile: items[0]?.fields?.menuImageMobile
        ? items[0]?.fields?.menuImageMobile?.fields?.file?.url
        : '',
    }
    menu.sectionImage2 = {
      url: items[1]?.fields?.menuImage ? items[1]?.fields?.menuImage?.fields?.file?.url : '',
      urlTablet: items[1]?.fields?.menuImageTablet
        ? items[1]?.fields?.menuImageTablet?.fields?.file?.url
        : '',
      urlMobile: items[1]?.fields?.menuImageMobile
        ? items[1]?.fields?.menuImageMobile?.fields?.file?.url
        : '',
    }
    menu.links = []
    menu.links2 = []
    menu.mobileOnly = items[0]?.fields?.mobileOnly || false
    menu.mobileOnly2 = items[1]?.fields?.mobileOnly || false
    items[0]?.fields?.menuItems.map((i) => {
      menu.links.push({
        title: i?.fields?.title,
        link: i?.fields?.url,
        titleDefault: i?.fields?.enTitle || i?.fields?.title,
        linkDefault: i?.fields?.enUrl || i?.fields?.url,
        mobileOnly: i?.fields?.mobileOnly || false
      })
    })
    items[1]?.fields?.menuItems.map((i) => {
      menu.links2.push({
        title: i?.fields?.title,
        link: i?.fields?.url,
        titleDefault: i?.fields?.enTitle || i?.fields?.title,
        linkDefault: i?.fields?.enUrl || i?.fields?.url,
        mobileOnly: i?.fields?.mobileOnly || false
      })
    })
  }
  return menu
}

export const fitText = (containerClass, contentClass) => {
  let container = document.getElementsByClassName(containerClass)

  try {
    if (!isEmptyObject(container) && container.length > 0) {
      for (let item of container) {
        let containerDimensions = item.getBoundingClientRect()
        let containerWidth = containerDimensions.width
        let content = item.querySelector(`.${contentClass}`)
        let contentWidth = content.scrollWidth

        if (parseInt(contentWidth) > parseInt(containerWidth)) {
          content.classList.add('break-all')
        }
      }
    }
  } catch (error) {}
}

export const logBuildError = (buildId, errorLog, slug) => {
  let pathFile = `./public/${buildId}.json`

  fs.readFile(pathFile, async (error, data) => {
    let errorData = {
      item: slug,
      error: errorLog,
    }

    if (error != null && error.code === 'ENOENT') {
      await fs.promises.writeFile(pathFile, JSON.stringify([errorData]))
    } else {
      let errors = JSON.parse(data.toString())
      let errorCount = errors.length

      let errorExists = errors.find((error) => error.item == slug)

      if (typeof errorExists === 'undefined') {
        errors.push(errorData)
      }

      if (errorCount < errors.length) {
        await fs.promises.writeFile(pathFile, JSON.stringify(errors))
      }
    }
  })
}

export const logMemoryUsage = () => {
  const used = process.memoryUsage()

  for (let key in used) {
    console.log(`Memory: ${key} ${Math.round((used[key] / 1024 / 1024) * 100) / 100} MB`)
  }
  console.log('-----')
}

export const getPageTypeFromRouter = (router) => {
  if (router.components) {
    if (
      router.components['/[...slug]'] &&
      router.components['/[...slug]']['props'] &&
      router.components['/[...slug]']['props']['pageProps'] &&
      router.components['/[...slug]']['props']['pageProps']['product']
    ) {
      return 'product'
    }
  }

  return 'page'
}

export const setInternalUrl = (path) => {
  return `${process.env.NEXT_PUBLIC_BASE_URL}${publicRuntimeConfig.basePath}${addForwardSlash(
    path
  )}`
}

////////// Get Country Data ///////////

export const getCountrySelectorData = () => {
  let pathname = window.location.pathname.split('/').filter((n) => n)
  let region = ''
  let pagePath = ''
  if (pathname[0] == 'us') {
    region = 'us/en-us'
    pagePath = pathname.slice(2, pathname.length).join('/')
  } else {
    region = pathname[0]
    pagePath = pathname.slice(1, pathname.length).join('/')
  }
  const countryData = countriesList.find((item) => item.prefix == region)
  countryData.pagePath = pagePath
  return countryData
}

export const createAlternateLinks = (paths, defaultPath, useDefaultPath = false) => {
  if (typeof paths == 'undefined') {
    return []
  }

  const regions = getAllRegions()
  const alternateLinks = []

  if (regions.length > 0) {
    regions.forEach((region) => {
      const domain = region.domain == 'en-us' ? 'us/en-us' : region.domain
      const locale =
        region && region.domain == 'en-us'
          ? 'en-US'
          : region.localePrefix == 'en'
          ? 'en-GB'
          : region.localePrefix
      if (!useDefaultPath) {
        const path = paths[locale] ? paths[locale] : null

        if (path != null) {
          alternateLinks.push({
            hrefLang: region.hreflang,
            href: `${process.env.NEXT_PUBLIC_BASE_URL}/${domain}${addForwardSlash(path)}`,
          })
        }
      } else {
        alternateLinks.push({
          hrefLang: region.hreflang,
          href: `${process.env.NEXT_PUBLIC_BASE_URL}/${domain}${
            defaultPath == '' ? '' : addForwardSlash(defaultPath)
          }`,
        })
      }
    })

    const xDefaultPath = useDefaultPath || !paths['en-US'] ? defaultPath : paths['en-US']
    alternateLinks.push({
      hrefLang: 'x-default',
      href: `${process.env.NEXT_PUBLIC_BASE_URL}/us/en-us${
        xDefaultPath == ''
          ? ''
          : addForwardSlash(Array.isArray(xDefaultPath) ? xDefaultPath.join('/') : xDefaultPath)
      }`,
    })
  }

  return alternateLinks
}

export const formatPrice = (val) => {
  return Number(val).toFixed(2)
}

export const removeTrailingSlash = (str) => {
  return str.replace(/\/+$/, '')
}

export const removeSlash = (str) => {
  return str.replace('/', '')
}

export const isJsonString = (str) => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }

  return true
}

//get user current domain
export const getCurrentUserDomain = () => {
  let pathname = localStorage.getItem('bclang')
  if (!pathname && typeof window !== 'undefined') {
    let pathname = window.location.pathname.split('/').filter((n) => n)
    let pagePath = ''
    if (pathname[0] == 'us') {
      pagePath = pathname[1]
    } else {
      pagePath = pathname[0]
    }
    return pagePath
  }
  if (pathname) {
    return pathname
  }
  return 'en-us'
}

export const displayPrice = (price) => {
  return parseFloat(price).toFixed(2)
}

export const trimObject = (obj) => {
  let newObj = { ...obj }

  Object.keys(newObj).map(
    (k) => (newObj[k] = typeof newObj[k] == 'string' ? newObj[k].trim() : newObj[k])
  )

  return newObj
}

export const formatSlugArray = (slug) => {
  if (typeof slug == 'string') {
    return slug
  }

  if (typeof slug == 'object' && Array.isArray(slug)) {
    let newSlug = slug[0]

    if (slug.length > 1) {
      newSlug = slug.join('/')
    }

    return newSlug
  }
}

export const transformFitValue = (value) => {
  if (value >= 1 && value <= 1.4) {
    return 1
  }

  if (value > 1.4 && value <= 1.8) {
    return 2
  }

  if (value > 1.8 && value <= 2.2) {
    return 3
  }

  if (value > 2.2 && value <= 2.6) {
    return 4
  }

  if (value > 2.6) {
    return 5
  }

  return value
}

export const isYoutubeUrl = (url) => {
  let p =
    /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/

  if (url.match(p)) {
    return true
  }

  return false
}

export const addUrlProtocol = (url) => {
  let prefix = 'https:'
  let newUrl = url
  if (newUrl.substr(0, prefix.length) !== prefix) {
    newUrl = prefix + newUrl
  }

  return newUrl
}

export const flattenGraphqlArray = (obj) => {
  try {
    return obj?.edges?.map((item) => item?.node)
  } catch (error) {
    console.error('flattenGraphqlArray error', error)
    return []
  }
}

export const createProductSlug = (product, metafields, localePrefix) => {
  let productName

  let keySuffix = '_full_product_name'
  let key = `${localePrefix}${keySuffix}`

  let newProductName = metafields.find((item) => item.key == key)
  if (newProductName) {
    newProductName = newProductName.value.replace(/[{"+}]/g, '').toLowerCase()
    newProductName = newProductName.includes(':') ? newProductName.split(':')[1] : newProductName
    newProductName = newProductName.replace(/ä/g, 'ae')
    newProductName = newProductName.replace(/ö/g, 'oe')
    newProductName = newProductName.replace(/ü/g, 'ue')
    newProductName = newProductName.replace(/ß/g, 'ss')
    newProductName = newProductName.replace(/\//g, '-') // replace / with _
    newProductName = newProductName.replace(/√§/g, 'ae') // replace german symbol
    newProductName = newProductName.normalize('NFD').replace(/[\u0300-\u036f]/g, '') // convert umlauts and accents to normal characters

    newProductName = newProductName.replace(/[^\w\s-]/gi, '') //replace non numeric characters
    productName = slugify(newProductName, { strict: true, remove: /[®™]/g })
  } else {
    productName = product.custom_url.url.slice(0, -1)
  }
  let slug = (productName + '-' + product.sku).toLowerCase().replace(/\//g, '')

  return slug
}

export const getLdPropsOffer = (product, canonicalUrl) => {
  const region = getRegion(process.env.NEXT_PUBLIC_SITE_REGION)
  const productVariants = product.variants
  const defaultPrice = {
    regularPrice: product.prices.regularPrice,
    salePrice: product.prices.salePrice,
    calculatedPrice: product.prices.calculatedPrice,
  }
  const offerArray = []
  for (let i = 0; i < productVariants.length; i++) {
    if (!productVariants[i].purchasing_disabled) {
      const variantImage =
        productVariants[i]?.image_url || ''
      const variantSku = productVariants[i].sku
      const variantGtin = productVariants[i].upc
      const variantUrl = `${canonicalUrl}?variant=${productVariants[i].id}`
      const variantColor = productVariants[i].option_values.find(
        (option) => option.option_display_name == 'Color'
      )?.label
      const variantSize = productVariants[i].option_values.find(
        (option) => option.option_display_name == 'Size'
      )?.label
      const variantName = `${product.name} - ${variantColor} - ${variantSize}`
      let variantPrice = {
        regularPrice: productVariants[i].price,
        salePrice: productVariants[i].sale_price,
        calculatedPrice: productVariants[i].calculated_price,
      }
      if (region.countryCode != 'US') {
        variantPrice = getLocalizedVariantPrices(
          productVariants[i].metafields,
          region,
          defaultPrice
        )
      }
      offerArray.push({
        // type: 'Offer',
        gtin13: variantGtin,
        sku: variantSku,
        url: variantUrl,
        name: variantName,
        image: variantImage,
        price: variantPrice.calculatedPrice,
        priceCurrency: region.isoCode == 'FIN' ? 'EUR' : region.isoCode,
        itemCondition: 'https://schema.org/NewCondition',
        availability:
          productVariants[i].inventory_level > 0
            ? 'https://schema.org/InStock'
            : 'https://schema.org/OutOfStock',
      })
    }
  }
  return offerArray
}

export const isAbsoluteUrl = (url) => {
  return /^(?:\w+:)?\/\//.test(url)
}

export const getLinkType = (url = '/') => {
  if (url.startsWith('#')) {
    return 'modal'
  }

  if (url.startsWith('http')) {
    return 'external'
  }

  return 'internal'
}

export const getMainDomain = (hostname) => {
  const parts = hostname.split('.');

  if (parts.length >= 2) {
    const tld = parts.pop();
    const sld = parts.pop();
    return "."+sld+"."+tld;
  }
  return hostname;
}
