const container = () =>{
  return ({ addComponents, config}) => {
    addComponents([
      {
        '.container': {
          maxWidth: '100%',
          margin: '0 auto',
          '@screen sm': {
            maxWidth: '640px'
          },
          '@screen md': {
            maxWidth: '768px'
          },
          '@screen lg': {
            maxWidth: '1024px'
          },
          '@screen xl': {
            maxWidth: '1920px'
          },
          '@screen 2xl': {
            maxWidth: '2560px'
          }
        },
        '.container-xl': {
          maxWidth: '1920px',
          margin: '0 auto',
        }
      }
    ])
  }
}

module.exports = container;